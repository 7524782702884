export class RomanNumerals {

  private chRomanNumerals: string[][] = [
    ['I', '\u266fI', 'ii', '\u266fii', 'iii', 'IV', '\u266fIV', 'V', '\u266fV', 'vi', '\u266fvi','vii'],
    ['I', '\u266dii', 'ii', '\u266diii', 'iii', 'IV', '\u266dV', 'V', '\u266dvi', 'vi', '\u266dvii','vii\u00B0'],
    ['i', '\u266fi','ii', 'III', '\u266fIII', 'iv', '\u266fiv', 'v', 'VI', '\u266fVI', 'V', '\u266fV'],
    ['i', '\u266dii','ii', '\u266dIII', 'III', 'iv', '\u266dv', 'v', '\u266dVI', '\u266d VII', '\u266dVII', '\u266dvi']
];

  constructor() {
  }

  processChord(chord: string, tempOffset: number): string {
      // Regular expression to match the first letter and an optional '#' or 'b'
      const regex = /^[A-Ga-g](#|b)?/;
      console.log("ROMan: " + tempOffset);
      return chord.replace(regex, this.chRomanNumerals[1][tempOffset]);
  }

  processInnerLabel(chord: string, tempOffset: number): string{
    if(tempOffset < 0){
      tempOffset += 12;
    }
    
    return this.chRomanNumerals[1][tempOffset];
  }
}