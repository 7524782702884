import {WShape} from './WShape';

export class WNote extends WShape {
    private isPlaying: boolean;
    private currentNotesColors: number[][];
    private bendValue: number;
    private isBending: boolean;
    private bendDrag: number;
    private channel: number;
    private bendFunction: (() => void) | null;

    constructor(object: any) {
        super(object);
        this.isPlaying = false;
        this.currentNotesColors = [];
        this.isBending = false;
        this.bendValue = 0;
        this.bendDrag = 0;
        this.bendFunction = null;
        
    }

    // Additional methods and properties specific to WNote
    play(hsla: number[]): void {
        this.isPlaying = true;
        // Add logic for playing the note
        this.currentNotesColors.push(hsla);
        //console.log("currentNotesColors: " + this.currentNotesColors.length);
    }

    stop(channel: number, noteNum: number): void {
        
        this.isPlaying = false;
        // Add logic for stopping the note
        if(this.currentNotesColors.length <= 0){
            
            this.visible = false;
        }else{
            var test = this.currentNotesColors.pop();
            if(this.currentNotesColors.length >= 1){
                const [targetHue, targetSat, targetLightness, targetAlpha] = this.currentNotesColors[this.currentNotesColors.length-1];
                this._shape.stroke = `hsla(${targetHue}, ${targetSat}%, ${targetLightness}%, ${targetAlpha})`;
                //this.startFlashing([targetHue,targetSat,targetLightness,0], [targetHue, targetSat, targetLightness, targetAlpha],20);
            }else{
                const [targetHue, targetSat, targetLightness, targetAlpha] = test;
                this.startFlashing([channel * 70,100,(noteNum * 0.8 + 10),0], [channel * 70, 100, (noteNum * 0.8 + 10), 100],20);
                //this.visible = false;
            }
            
        }
            
        
    }

    clearCurrentNotesColors(): void {
        this.currentNotesColors = [];

    }

    bendStart(): void {
        this.isBending = true;
        this.bendDrag = 0.6;
        // Add logic for starting the bend
    }

    bendStop(): void {
        this.isBending = false;
        this.bendDrag = 0;
        // Add logic for stopping the bend
    }

    bend(bendVal: number): void {
        this.isBending = true;
        //this.shakeEndTime = Date.now() + duration;
        this.bendFunction = this.createBendFunction(bendVal);
    }

    private createBendFunction(bendVal: number): () => void {

      return () => {
          if (!this.isBending) return;

          /*if (elapsedTime > duration) {
              this.isBending = false;
              this._shape.position.set(this.originalPosition.x, this.originalPosition.y);
              return;
          }*/

          //this._shape.rotation = 0;
      };    
  }
    // Override the update method if needed, or add additional methods
    update(): void {
        super.update();
        // Add any additional update logic specific to WNote
        if (this.isBending && this.bendFunction) {
            this.bendFunction();
        }
    }

    // Add getters and setters if needed
    getIsPlaying(): boolean {
        return this.isPlaying;
    }

    getIsBending(): boolean {
        return this.isBending;
    }
}
