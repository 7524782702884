export class WScale{

  public static scaleChords: number[][] = [
    [1,8,2,8,2,1,8,1,8,2,8,8],//major wDim
    [1,0,2,0,2,1,0,1,0,2,0,8],//major
    [2,8,8,1,8,2,8,2,1,8,1,8],//minor wDim
    [2,0,8,1,0,2,0,2,1,0,1,0]//minor
  ];

  public static scale: { [key: string]: number[] } = {
    "": [0,0,0,0,0,0,0,0,0,0,0,0],
    "(Root Note Only)": [1,0,0,0,0,0,0,0,0,0,0,0],
    "Chromatic": [1,1,1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
    "Whole Tone": [1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0],
    "Diminished": [1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0],
    "Augmented": [1, 0, 0, 0, 1, 0, 0, 0, 1, 0, 0, 0],
    "Tritone Interval": [1, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0],
    "Major": [1, 0, 1, 0, 1, 1, 0, 1, 0, 1, 0, 1],
    "Minor": [1, 0, 1, 1, 0, 1, 0, 1, 1, 0, 1, 0],
    "Ionian": [1, 0, 1, 0, 1, 1, 0, 1, 0, 1, 0, 1],
    "Dorian": [1, 0, 1, 1, 0, 1, 0, 1, 0, 1, 1, 0],
    "Phrygian": [1, 1, 0, 1, 0, 1, 0, 1, 1, 0, 1, 0],
    "Lydian": [1, 0, 1, 0, 1, 0, 1, 1, 0, 1, 0, 1],
    "Mixolydian": [1, 0, 1, 0, 1, 1, 0, 1, 0, 1, 1, 0],
    "Aeolian": [1, 0, 1, 1, 0, 1, 0, 1, 1, 0, 1, 0],
    "Locrian": [1, 1, 0, 1, 0, 1, 1, 0, 1, 0, 1, 0],
    "Blues": [1, 0, 0, 1, 0, 1, 1, 1, 0, 0, 1, 0],
    "Major Pentatonic": [1, 0, 1, 0, 1, 0, 0, 1, 0, 1, 0, 0],
    "Minor Pentatonic": [1, 0, 0, 1, 0, 1, 0, 1, 0, 0, 1, 0],
    "Harmonic Minor": [1, 0, 1, 1, 0, 1, 0, 1, 1, 0, 0, 1],
    "Harmonic Major": [1, 0, 1, 0, 1, 1, 0, 1, 1, 0, 0, 1],
    "Half-Whole Diminished": [1, 1, 0, 1, 1, 0, 1, 1, 0, 1, 1, 0],
    "Whole-Half Diminished": [1, 0, 1, 1, 0, 1, 1, 0, 1, 1, 0, 1],
    "Melodic Minor": [1, 0, 1, 1, 0, 1, 0, 1, 0, 1, 0, 1],
    "Altered Dominant": [1, 1, 0, 1, 1, 0, 1, 0, 1, 0, 1, 0],
    "Phrygian Dominant": [1, 1, 0, 0, 1, 1, 0, 1, 1, 0, 1, 0],//egyptian
    "Spanish": [1, 1, 0, 1, 1, 1, 1, 0, 1, 0, 1, 0],
    "Bhairav": [1, 1, 0, 1, 1, 0, 1, 1, 0, 0, 0, 1],
    "Hungarian Minor": [1, 0, 1, 1, 0, 0, 1, 1, 1, 0, 0, 1],
    "Hirajoshi": [1, 0, 1, 1, 0, 0, 0, 1, 1, 0, 0, 0],
    "In-sen": [1, 1, 0, 0, 0, 1, 0, 1, 0, 0, 1, 0],
    "Iwato": [1, 1, 0, 0, 0, 1, 1, 0, 0, 0, 1, 0],
    "Kumoi": [1, 0, 1, 1, 0, 0, 0, 1, 0, 1, 0, 0],
    "Selisir": [1, 1, 0, 1, 0, 0, 0, 1, 1, 0, 0, 0],
    "Tembung": [1, 1, 0, 0, 0, 1, 0, 1, 1, 0, 0, 0],
    "Messiaen 3": [1, 0, 1, 1, 1, 0, 1, 1, 1, 0, 1, 1],
    "Messiaen 4": [1, 1, 1, 0, 0, 1, 1, 1, 1, 0, 0, 1],
    "Messiaen 5": [1, 1, 0, 0, 0, 1, 1, 1, 0, 0, 0, 1],
    "Messiaen 6": [1, 0, 1, 0, 1, 1, 1, 0, 1, 0, 1, 1],
    "Messiaen 7": [1, 1, 1, 1, 0, 1, 1, 1, 1, 1, 0, 1]
  };

  get(s: number | string){
    if(typeof s == "number"){
      let keys = Object.keys(WScale.scale);
      if(s < keys.length){
        let key = keys[s];
        return WScale.scale[key];
      }else{
        return WScale.scale[""];
      }
      
    }else{
      return WScale.scale[s];
    }
  }
  getScaleName(index: number){
    let keys = Object.keys(WScale.scale);
    if(index < keys.length){
      return keys[index];
    }else{
      return keys[0];
    }
  }
  
  getAllScales(){
    return WScale.scale;
  }

  getScaleChords(s: number | string){
    return WScale.scaleChords[s];
  }

}


