export class WSong{
  private _title: string;
  private _artist: string;
  private _composer: string;
  private _id: string;//Youtube ID
  private _startTime: number;//video start time
  private _endTime: number;
  private _bpm: number;
  private _midiFile: string;
  private _tempoMap: number[];
  private _chordProgression: string[][];
  private _timeSignature: [number | number[], string][];
  private _key: string[][];

  constructor(title: string, artist: string, composer: string, id: string, startTime: number, endTime: number, bpm: number, midiFile: string, tempoMap: number[], chordProgression: string[][], timeSignature: [number | number[], string][], key: string[][]) {
    this._title = title;
    this._artist = artist;
    this._composer = composer;
    this._id = id;
    this._startTime = startTime;
    this._endTime = endTime;
    this._bpm = bpm;
    this._midiFile = midiFile;
    this._tempoMap = tempoMap;
    this._chordProgression = chordProgression;
    this._timeSignature = timeSignature;
    this._key = key;
  }

  // Getters
  get title(): string {
    return this._title;
  }

  get artist(): string {
    return this._artist;
  }

  get composer(): string {
    return this._composer;
  }

  get id(): string {
    return this._id;
  }

  get startTime(): number {
    return this._startTime;
  }

  get endTime(): number {
    return this._endTime;
  }

  get bpm(): number {
    return this._bpm;
  }

  get midiFile(): string {
    return this._midiFile;
  }

  get tempoMap(): number[] {
    return this._tempoMap;
  }

  get chordProgression(): string[][] {
    return this._chordProgression;
  }

  get timeSignature(): [number, string][] {
    return this._timeSignature;
  }

  get key(): string[][] {
    return this._key;
  }

  // Setters
  set title(value: string) {
      this._title = value;
  }

  set artist(value: string) {
     this._artist = value;
  }

  set composer(value: string) {
     this._composer = value;
  }

  set id(value: string) {
     this._id = value;
  }

  set startTime(value: number ) {
     this._startTime = value;
  }

  set endTime(value: number ) {
     this._endTime = value;
  }

  set bpm(value: number) {
     this._bpm = value;
  }

  set midiFile(value: string) {
    this._midiFile = value;
 }

  set tempoMap(value: number[] ) {
     this._tempoMap = value;
  }

  set chordProgression(value: string[][]) {
     this._chordProgression = value;
  }

  set timeSignature(value: [number, string][]) {
     this._timeSignature = value;
  }

  set key(value: string[][]) {
    this._key = value;
  }
}