export class WChords{

  /*private static const chord: number[][] = [
    [1, 0, 0, 0, 1, 0, 0, 1, 0, 0, 0, 0],//major
    [1, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0],//minor
    [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],//chromatic
    [1, 0, 1, 0, 1, 1, 0, 1, 0, 1, 0, 1],//major
    [1, 0, 1, 1, 0, 1, 0, 1, 1, 0, 1, 0], //minor
    [1, 0, 1, 1, 0, 1, 0, 1, 1, 0, 0, 1],
    [1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0], //wholetone
    [1, 1, 0, 1, 1, 0, 1, 0, 1, 0, 1, 0],//altDominant
    [1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0],
    [1, 0, 0, 0, 1, 0, 0, 0, 1, 0, 0, 0],
    [1, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0],//tritone

  ];*/

  private static chordLabels = {
    'M': [],
    'maj7': [],
    'min7': []
};

  private static triad: number[][] = [
    [0],
    [0,4,7],//major (1)
    [0,3,7],//minor (2)
    [0,2,7],//sus2 (3)
    [0,5,7],//sus4 (4)
    [0,2,4,7],//Madd2 (5)
    [0,2,3,7],//madd2 (6)
    [0,4,8],//aug (7)
    [0,3,6],//dim (8)
    [0,4,9],//M6 make this a tension?
    [0,3,9],//m6
    [0,3,8],//minorb6
  ];
  private static tension: number[][] = [
    [9],//M6
    [8],//m6
    [11],//major7th
    [10],//minor7th
    [2,11],//major 9
    [2,10],//minor 9
    [1,11],//major b9
    [1,10],//minor b9
    [3,11],//major#9
    [3,10],//minor#9
    [5, 11],//major 11
    [5, 10],//minor 11
    [6, 11],//major #11
    [6, 10],//minor #11
    
    [5, 11], [2,5,11],[1,5,11],[3,5,11],//major 11 w9s
    [5, 10],[2,5,10],[1,5,10],[3,5,10],//minor 11 w9s
    [6, 11],[2,6,11],[1,6,11],[3,6,11],//major #11 w 9s
    [6, 10],[2,6,10],[1,6,10],[3,6,10],//minor #11 w 9s

    [9, 11],//M7 13
    [9, 10]//b7 13

  ];

  getRoot(offset: number){
    var rootSend: number[] = [];
    if(offset >= 12 || offset < 0){
      console.log("offset is invalid: must be a value from 0 - 11!")
    }
    for(var i = 0; i < 12; i++){
      if(i == offset){
        rootSend.push(1);
      }else{
        rootSend.push(0);
      }
    }
    return rootSend;
  }
  
  getTriad(offset: number, type: number | string){
    var triadSend: number[] = [0,0,0,0,0,0,0,0,0,0,0,0];
    var k: number = 0;
    for(var i = offset; i < 12 + offset; i++){
        
      if(i == ((WChords.triad[type][k] + offset))){
        triadSend[i % 12] = 1;
        k++;
      }
    }
    //console.log(triadSend);
    return triadSend;

  }

  getTension(s: number){
    if (s < WChords.tension.length){
      return WChords.tension[s];
    }
  }
  
  getAllChordsArray(){
    return WChords.triad;
  }
}