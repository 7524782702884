// WShape.ts

export class WShape {
    public _shape: any; // Replace with the actual type of your Two.js object
    private isFlashing: boolean;
    private flashFunction: (() => void) | null;
    private targetHSLA: [number, number, number, number];
    private flashHSLA: [number, number, number, number];
    private duration: number;
    private isShaking: boolean;
      private shakeFunction: (() => void) | null;
      private shakeEndTime: number;
      private originalPosition: { x: number; y: number };
  
    constructor(object: any) {
        this._shape = object;
        this.isFlashing = false;
          this.flashFunction = null;
          this.targetHSLA = [0, 0, 0, 1]; // Default HSLA values
          this.flashHSLA = [0, 0, 100, 1]; // Default flash color (white)
          this.duration = 0;
          this.isShaking = false;
          this.shakeFunction = null;
          this.shakeEndTime = 0;
          this.originalPosition = {x: object.position.x, y: object.position.y};
    }
  
    // Function to start the flashing process
    startFlashing(targetHSLA: [number, number, number, number], flashHSLA: [number, number, number, number], duration: number): void {
      this.isFlashing = true;
      this.targetHSLA = targetHSLA;
      this.flashHSLA = flashHSLA;
      this.duration = duration;
      this.flashFunction = this.createFlashFunction();
    }
  
    // Internal function to handle the flashing logic
    private createFlashFunction(): () => void {
      let startTime: number | null = null;
      const [targetHue, targetSaturation, targetLightness, targetAlpha] = this.targetHSLA;
      const [flashHue, flashSaturation, flashLightness, flashAlpha] = this.flashHSLA;
  
      return () => {
          if (startTime === null) {
              startTime = Date.now();
              this._shape.stroke = `hsla(${flashHue}, ${flashSaturation}%, ${flashLightness}%, ${flashAlpha})`;
          }
  
          const elapsedTime = Date.now() - startTime;
          const progress = elapsedTime / this.duration;
  
          if (progress >= 1) {
              this.isFlashing = false;
              this._shape.stroke = `hsla(${targetHue}, ${targetSaturation}%, ${targetLightness}%, ${targetAlpha})`;
              return;
          }
  
          let currentLightness = flashLightness + (targetLightness - flashLightness) * progress;
          let currentAlpha = (flashAlpha + (targetAlpha - flashAlpha) * progress) * 0.01;
          //console.log("ALPHA: " + currentAlpha);
          let currentHue = flashHue + (targetHue - flashHue) * progress;
          this._shape.stroke = `hsla(${targetHue}, ${targetSaturation}%, ${currentLightness}%, ${currentAlpha})`;
      };
  }
  shake(shakeDistance: number, duration: number): void {
          this.isShaking = true;
          this.shakeEndTime = Date.now() + duration;
          this.shakeFunction = this.createShakeFunction(shakeDistance, duration);
      }
  
      private createShakeFunction(shakeDistance: number, duration: number): () => void {
        const startTime = Date.now();

        return () => {
            if (!this.isShaking) return;

            const elapsedTime = Date.now() - startTime;
            if (elapsedTime > duration) {
                this.isShaking = false;
                this._shape.position.set(this.originalPosition.x, this.originalPosition.y);
                return;
            }

            // Calculate the current shake intensity
            const remainingTime = duration - elapsedTime;
            const currentShakeIntensity = (shakeDistance * remainingTime) / duration;

            const randomX = (Math.random() * currentShakeIntensity) - currentShakeIntensity / 2;
            const randomY = (Math.random() * currentShakeIntensity) - currentShakeIntensity / 2;
            this._shape.position.set(this.originalPosition.x + randomX, this.originalPosition.y + randomY);
        };
    }

      // Update function to be called in the main animation loop
    update(): void {
        if (this.isFlashing && this.flashFunction) {
            this.flashFunction();
        }

        if (this.isShaking && this.shakeFunction) {
            this.shakeFunction();
        }
    }
  
    // Getter for the object
      get shape(): any {
          return this._shape;
      }
  
  // Setter for the object
      set shape(value: any) {
          this._shape = value;
          // You can add additional logic here if needed when the object changes
      }
      set position (vector: any){
            this._shape.position.set(vector[0], vector[1]);
      } 

      set width (value: number){
            this._shape.width = value;
      }
      set height (value: number){
            this._shape.height = value;
      }
      set radius(value: any){
          this._shape.radius = value;
      }
      set dash(value: any) {
          this._shape.dashes = value;
          // You can add additional logic here if needed when the object changes
      }
      set dashOffset(value: any){
          this._shape.dashes.offset = value;
      }
      set stroke(value: string){
          this._shape.stroke = value;
      }
      set visible(value: boolean){
          this._shape.visible = value;
      }
      set fill(value: any){
          //if(value == true){
              this._shape.fill = value;
          //}else{
          //    this._shape.noFill();
          //}
      }

      get scale(): number{
        return this._shape.scale;
    }
      set scale(value: any){
          this._shape.scale = value;
      }

      get linewidth(): number{
          return this._shape.linewidth;
      }
      set linewidth(value: number){
          this._shape.linewidth = value;
      }

      get rotation(): number {
        return this._shape.rotation;
    }
      set rotation(value: number){
        if (typeof value === 'number' && !isNaN(value)) {
            this._shape.rotation = value;
        } else {
            console.error('Invalid rotation value:', value);
        }
      }
  
      noFill = () => {
          this._shape.noFill();
      }
    // Add any other methods or properties you need for your _shape
  }
  