import Two from 'two.js';
import { WebMidi } from '../node_modules/webmidi/dist/esm/webmidi.esm.js';
import * as Tone from 'tone';
import { Midi as ToneMidi} from '@tonejs/midi';
import { BasicPitch, noteFramesToTime, addPitchBendsToNoteEvents, outputToNotesPoly } from '@spotify/basic-pitch';
import { Midi as TonalMidi, Chord, Note, Scale, ScaleType, Interval } from 'tonal';
import { WScale } from "./WScale";
import audioToNoteEvents from './BasicPitchFunctions.js'
import type {NoteEvent, detectionOptions, onSuccessCallback, onErrorCallback, onProgressCallback} from './BasicPitchFunctions.js';
import { Circle } from 'two.js/src/shapes/circle';
import { WChords } from './WChords.js';
import { WShape } from './WShape.js';
import { WNote } from './WNote.js';
import { WSong } from './WSong.js';
import { RomanNumerals} from './RomanNumerals.js';
//import { RoundedRectangle } from 'two.js/src/shapes/rounded-rectangle';
//import { Ticks } from 'tone/build/esm/core/type/Units.js';

//const muteBTN = document.getElementById("mute-btn");
const muteBTN: HTMLButtonElement = document.querySelector('#mute-btn') as HTMLButtonElement;
const analyzeBTN: HTMLButtonElement = document.querySelector('#analyze-btn') as HTMLButtonElement;
//const inputSelector: HTMLSelectElement = document.querySelector("#inputSelect") as HTMLSelectElement;
const scaleSelector: HTMLSelectElement = document.querySelector("#scaleSelect") as HTMLSelectElement;
const labelSelector: HTMLSelectElement = document.querySelector("#labelSelect") as HTMLSelectElement;
const octaveSelector: HTMLSelectElement = document.querySelector("#octSelect") as HTMLSelectElement;
const autoRotationSelector: HTMLSelectElement = document.querySelector('#autoRotateSelect') as HTMLSelectElement;
const chordDetectBTN: HTMLButtonElement = document.querySelector('#chordDetect-btn') as HTMLButtonElement;
const TWO_PI: number = Math.PI * 2;


var labelRot: number = Math.PI / 2;
var keyRot: number = Math.PI / 2;
var prevKeyRot: number = 0;
var currKeyAngle = 0;
var newKeyAngle = currKeyAngle;
var currLabelAngle = 0;
var currLabelPosition = calculatePosition(labelRot);
//var prevLabelPosition = currLabelPosition;
var currKeyPosition = currLabelPosition;
//var wheelRotationValue: number = 0;
var wheelNoteClicked: boolean = false;
//var prevWheelNoteClicked: boolean = false;
var currWheelNote: number = 0;
var prevWheelNote:number = 0;
//var prevWheelRotationValue: number = wheelRotationValue;
var wheelRotationTurns: number = 0;
var totalRotation: number = 0;


var twoElem = document.getElementById('svg-container');
const two = new Two({
    type: Two.Types.svg,
    fullscreen: true,
    autostart: true,
    width: window.innerWidth * 0.99 || document.documentElement.clientWidth || document.body.clientWidth,
    height: window.innerHeight * 0.94 || document.documentElement.clientHeight || document.body.clientHeight,
}).appendTo(twoElem);
var outsideRadius: number = Math.min(two.width, two.height) * 0.3;
if(two.width > two.height){
    outsideRadius = Math.min(two.width, two.height) * 0.3;
}else{
    outsideRadius = two.width * 0.38;
}
var insideRadius: number = Math.min(two.width, two.height) * 0.1;

// Get the current page scroll position in the vertical direction
var scrollTop = window.scrollY || document.documentElement.scrollTop;
// Get the current page scroll position in the horizontal direction 

var scrollLeft = window.scrollX || document.documentElement.scrollLeft;

// if any scroll is attempted,
// set this to the previous value
window.onscroll = function() {
    window.scrollTo(scrollLeft, scrollTop);
};

var drag: number = 0.5;
//let currentTime: number = 0;
two.renderer.domElement.style.background = 'rgba(12, 0, 33, 0.85)';

const ON: number = 1;
const OFF: number = 0;

const numSteps:number = 12;
//const onOff:number = 1;//unnecessary
const chordLetters: string[][] = [
    ['', '', '', '', '', '', '', '', '', '', '', '', ''],
    ['R', '\u266f1', '2', 'C', 'C\u266f', 'D', 'D\u266f', 'E', 'F', 'F\u266f', 'G', 'G\u266f', 'J'],
    ['1', '\u266d2', '2', '\u266d3', '3', '4', '\u266d5', '5', '\u266d6', '6', '\u266d7', '7', '',
    '\u266d9', '9', '', '','11','\u266f11','','\u266d13','13','','',''],
];
const noteLetters: string[][] = [
    ['', '', '', '', '', '', '', '', '', '', '', '', ''],
    ['A', 'A\u266f', 'B', 'C', 'C\u266f', 'D', 'D\u266f', 'E', 'F', 'F\u266f', 'G', 'G\u266f', 'J'],
    ['A', 'B\u266d', 'B', 'C', 'D\u266d', 'D', 'E\u266d', 'E', 'F', 'G\u266d', 'G', 'A\u266d', 'J'],
    ['6','\u266f6', '7', '1', '\u266f1', '2', '\u266f2', '3', '4', '\u266f4', '5', '\u266f5', 'J'],
    ['6','\u266d7', '7', '1', '\u266d2', '2', '\u266d3', '3', '4', '\u266d5', '5', '\u266d6', 'J'],
    ['vi','\u266dvii', 'vii', 'I', '\u266dii', 'ii', '\u266diii', 'iii', 'IV', '\u266dV', 'V', '\u266dvi', 'J'],
    ['M6','m7', 'M7', 'unison', 'm2', 'M2', 'm3', 'M3', 'P4', 'A4/Dim5', 'P5', 'm6', 'J'],
    ['A', '', 'B', 'C', '', 'D', '', 'E', 'F', '', 'G', '', 'J']
];

var noteLetterType:number = 1;

/*const chRomanNumerals: string[][] = [
    ['I', '\u266fI', 'ii', '\u266fii', 'iii', 'IV', '\u266fIV', 'V', '\u266fV', 'vi', '\u266fvi','vii'],
    ['I', '\u266dii', 'ii', '\u266diii', 'iii', 'IV', '\u266dV', 'V', '\u266dvi', 'vi', '\u266dvii','vii'],
    ['i', '\u266fi','ii', 'III', '\u266fIII', 'iv', '\u266fiv', 'v', 'VI', '\u266fVI', 'V', '\u266fV'],
    ['i', '\u266dii','ii', '\u266dIII', 'III', 'iv', '\u266dv', 'v', 'VI', '\u266d VII', 'V', '\u266dvi']
];*/
const romanNumerals: RomanNumerals = new RomanNumerals();
var useRomanNumerals: boolean = true;
const defaultNoteLetters = 1;

var notePositions: number[][] = [];//empty array
const styles:{
    size: number,
    family: string,
    fill: string,
    opacity: number
} = {
    size: outsideRadius * 0.15,
    family: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    fill: 'white',
    opacity: 0.87//.38, .60, or .87 
};

const innerStyle =
{
    size: insideRadius * 0.4,
    family: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    fill: 'white',
    opacity: 0.87
};

/***************************************************
* MAIN RING
***************************************************/
const mainRadiusOuterOffset: number = outsideRadius * 0.9;
const mainRadiusInnerOffset: number = outsideRadius * 0.4;
const mainRadius: number = ((mainRadiusOuterOffset - mainRadiusInnerOffset) / 2) + mainRadiusInnerOffset;
const mainRadiusHalf: number = mainRadius * 0.5;
const mainDashArray: number[] = [];
const mainDashes: number[][] = [
    [//OFF (0)
        0, mainRadiusHalf + ((TWO_PI * mainRadius) / 12) - mainRadiusHalf
    ], [//ON (1)
        mainRadiusHalf, ((TWO_PI * mainRadius) / 12) - mainRadiusHalf
    ]
];

//SCALES
const scales: WScale = new WScale;
//Populate ScaleSelector
let loadScales = scales.getAllScales();
let keys = Object.keys(loadScales);
for(let i in keys){
    let opt = document.createElement("option");
    opt.value = keys[i];
    if(keys[i] != ""){
        opt.innerHTML = keys[i];
    }else{
        opt.innerHTML = "Off";
    }
    
    if(keys[i] == "Major"){
        opt.selected = true;
    }
    scaleSelector.appendChild(opt);
}

const chords: WChords = new WChords;

const chordRootDashArray: number[] = [];
const chordDashArray: number[] = [];
var currScale: number | string = "Major";
var sharpNotes: boolean = true;//true = #, false = b
var currKey: number = 0;//3 is C
var newKey: number = currKey;
var currChord: number = 1;
var currChordOffset: number = 0;
var keyFlag: boolean = false;//flag that tracks if Key Sprite clicked
var labelFlag: boolean = false;//flag tracks if Label Circle clicked
//var selectedLabelNum: number = 0;
//var wheelStartAngle: object;
var wheelStartAngle = {x: two.width/2, y: two.height/2};

var wheelPitchBend: number = 0;
var sustain: boolean[] = [false,false,false,false,false,false,false,false,false,false,false,false,false,false,false];
var sustainingNotes: number[][] = [[],[],[],[],[],[],[],[],[],[],[],[],[],[],[]];
var wheelModCC: number = 0;
var pcMessage: number = 0;
var myInputNum: number = 0;
var myInputs: number[] = [];
var myMidiInput: any;

var k: number = 0;
for (let i = 0; i < numSteps; i++) {//Initial Scale
    
    if (scales.get(currScale)[i] > 0) {
        mainDashArray.push(mainDashes[ON][0]); mainDashArray.push(mainDashes[ON][1]);
    } else {
        mainDashArray.push(mainDashes[OFF][0]); mainDashArray.push(mainDashes[OFF][1]);
    }
    chordRootDashArray.push(mainDashes[OFF][0]); chordRootDashArray.push(mainDashes[OFF][1]);
    chordDashArray.push(mainDashes[OFF][0]); chordDashArray.push(mainDashes[OFF][1]);

}
const keyRadius : number = outsideRadius * 1.15;

const labelCircle: Circle = two.makeCircle(0, 0, outsideRadius);
labelCircle.visible = false; labelCircle.noFill();
labelCircle.linewidth = styles.size; labelCircle.stroke = 'rgba(255,255,255, 0.1)';
const innerLabelCircle: Circle = two.makeCircle(0, 0, insideRadius);
innerLabelCircle.visible = false; innerLabelCircle.noFill();
innerLabelCircle.linewidth = 40; innerLabelCircle.stroke = 'rgba(255,255,255, 0.1)';

const keyCircle: Circle = two.makeCircle(0,0,keyRadius);
keyCircle.visible = false; keyCircle.noFill();
keyCircle.linewidth = styles.size; keyCircle.stroke = 'rgba(255,255,255,0.1';
keyCircle.rotation = -Math.PI/2;

const mainNoteArea: WShape = new WShape(two.makeCircle(0, 0, mainRadius));
mainNoteArea.noFill();
mainNoteArea.dash = mainDashArray;
mainNoteArea.dashOffset = mainRadiusHalf * 0.5;
mainNoteArea.linewidth = mainRadiusOuterOffset - mainRadiusInnerOffset;
mainNoteArea.stroke = 'rgba(255, 255, 255, 0.17)';

const chordRootCircle: WShape = new WShape(two.makeCircle(0,0, mainRadius));
chordRootCircle.noFill(); 
chordRootCircle.dash = chordRootDashArray;
chordRootCircle.dashOffset = mainRadiusHalf * 0.5;
chordRootCircle.linewidth = mainRadiusOuterOffset - mainRadiusInnerOffset; 
chordRootCircle.stroke = 'rgba(255,255,255, 0.5)';
chordRootCircle.visible = false;

const chordCircle: WShape = new WShape(two.makeCircle(0,0, mainRadius));
chordCircle.noFill(); 
chordCircle.dash = chordDashArray;
chordCircle.dashOffset = mainRadiusHalf * 0.5;
chordCircle.linewidth = mainRadiusOuterOffset - mainRadiusInnerOffset; 
chordCircle.stroke = 'rgba(0,125,255, 0.25)';
chordCircle.visible = false;

//Detection Circles
const detChCircle: WShape = new WShape(two.makeCircle(0,0, mainRadius));
detChCircle.noFill(); 
detChCircle.dash = chordDashArray;
detChCircle.dashOffset = mainRadiusHalf * 0.5;
detChCircle.linewidth = mainRadiusOuterOffset - mainRadiusInnerOffset; 
detChCircle.stroke = 'rgba(100,245,255, 0.1)';
detChCircle.visible = true;

const detChRootCircle: WShape = new WShape (two.makeCircle(0,0, mainRadius));
detChRootCircle.noFill();
detChRootCircle.dash = chordDashArray;
detChRootCircle.dashOffset = mainRadiusHalf * 0.5;
detChRootCircle.linewidth = mainRadiusOuterOffset - mainRadiusInnerOffset; 
detChRootCircle.stroke = 'rgba(255,255,255, 0.5)';
detChRootCircle.visible = true;


/***************************************************
* Ring Initialization
***************************************************/
//var ringRadii:number[] = [];//array stores the radii of each octave circle
//var ringRadiiHalf:number[] = [];// used to calcultae ring dashes

var numOctaves : number = 5;
var ringFit = mainNoteArea.linewidth / numOctaves;//7 octaves within the main note area
var octave:number = 0;//global octave for both wheel and virtual piano
var lowestNote : number = 0;
var highestNote : number = 96;
const rings: WNote[] = [];
if (numOctaves < 8){
    lowestNote = (octave * 12);
    highestNote = (octave * 12) + (numOctaves * 12);
    octave = 2;
    console.log("numOctaves less than 8");
}
console.log("numOctaves: " + numOctaves + ", currentOctave: " + octave);

setWheelZoom(numOctaves, octave, numSteps);
console.log("rings length: " + rings.length);

//Actual notes that are currently on 0-127
var currentNotesNum: any[] = [];//Midi value of each current note
var currentNotes: any[] = [];//string array for each current note name


/***************************************************
* DRAWS NOTE LETTERS
***************************************************/
var letters: Text2[] = [];
var innerLabelChars: Text2[] = [];
//var myPoints: Circle[] = [];
var letterPositions: number[] = [];
var innerLabelCharPositions: number[] = [];

for (let i = 0; i < 12; i++) {
    letterPositions.push(i / numSteps);
    var x: number = outsideRadius * Math.sin(letterPositions[i] * TWO_PI);
    var y: number = - outsideRadius * Math.cos(letterPositions[i] * TWO_PI);
    letters.push(new Two.Text(i === 0 ? noteLetters[defaultNoteLetters][0] : noteLetters[defaultNoteLetters][i], x, y, styles));
    
    innerLabelCharPositions.push(i / numSteps);
    var innerX: number = insideRadius * Math.sin(innerLabelCharPositions[i] * TWO_PI);
    var innerY: number = - insideRadius * Math.cos(innerLabelCharPositions[i] * TWO_PI);
    innerLabelChars.push(new Two.Text(i === 0 ? chordLetters[2][0] : chordLetters[2][i], innerX, innerY, styles));
    //innerLabelChars[i].size = innerStyle.size - (i * 0.1);

    const array: number[] = [x, y];
    notePositions.push(array);

}
innerLabelChars[0].size = innerStyle.size * 2;//root size?

var autoRotateSetting: number = 0; //0 = none, 1 = follow key, 2 = follow chord
//var detectChordSetting: boolean = false;//false = detectionCh only, true = all channels 
var chordDetection: boolean = false;
var detectionChannel: number = 16;
var chordName: string;
var detectedChord: string[];
//var currentChord: string[]; //This will be used for isPlaying chord!

var centerText = new Two.Text(chordName, 0, 0, styles);
let keyTextYPos = -outsideRadius -( ((two.height/2) - outsideRadius) / 2.5 );
//let keyTextYPos = -((two.height/2) - outsideRadius) - ( ((two.height/2) - outsideRadius) / 2 );
var keyText = new Two.Text(noteLetters[1][currKey], 0, keyTextYPos, styles);
//keyText.value = "KEY: " + noteLetters[noteLetterType][(currKey + 3) % numSteps] + " " + scaleSelector.options[currScale].text;
setKeyText();
// KEY SPRITE   
const keySprite = new Two.Sprite('./key.png',  letters[2].position.x - 35, letters[2].position.y, 1, 1, 1);
var keyPosition: number = 0;
keySprite.scale = two.height * 0.00018;

console.log("HEIGHT"  + two.height);

//const keyboard: Group = two.makeGroup();
var wheelOctave:number = 0;//defines note when clicking on wheel
var octLimit:number = 0;
if(numOctaves < 8){
    octLimit = 8 - numOctaves;
    console.log("octLimit: " + octLimit);
}
    

var keyboardVelocity: number = 90;
var keyboardOctave: number = 4;
const keyboardArray: string[][] = [
    ['a', 'A'],['w','W'],['s','S'],['e','E'],['d','D'],['f','F'],['t','T'],['g','G'],['y','Y'],['h','H'],['u', 'U'],['j','J'],
    ['k','K'],['o','O'],['l','L'],['p','P'],[';',':'],['\'','\"'],[']','}']
];

var allowed: boolean = false;
var mouseIsOverPiano: boolean = false;
var prevMouseIsOverPiano: boolean = false;


const pianoKeys: WShape[] = [];

var pianoHeight: number = (((two.height/2) - outsideRadius)/5) * 2;

const blackKeyHeightProportion = 0.6;
var blackKeyHeight: number = pianoHeight * blackKeyHeightProportion;
const whiteKeysY: number = two.height/2 - (pianoHeight/2);

var numPianoOctaves: number = 8;
var pianoZoomOctaves: number = numOctaves;
if (isMobileDevice()) {
    keySprite.scale *=1.4;  
    //numOctaves = 5; 
    pianoZoomOctaves = 5;
}
const pianoMap: number[] = [];

const firstBlackKeyIndex: number = numPianoOctaves * 7;
var firstBlackKeyValZoom: number = pianoZoomOctaves * 7;
//const lastWhiteKeyValue: number = numPianoOctaves * 7;

for(let i = 0; i < numPianoOctaves; i++){
    pianoMap.push(i * 7 + 0);
    pianoMap.push(firstBlackKeyIndex + (5 * i) + 0);
    pianoMap.push(i * 7 + 1);
    pianoMap.push(firstBlackKeyIndex + (5 * i) + 1);
    pianoMap.push(i * 7 + 2);
    pianoMap.push(i * 7 + 3);
    pianoMap.push(firstBlackKeyIndex + (5 * i) + 2);
    pianoMap.push(i * 7 + 4);
    pianoMap.push(firstBlackKeyIndex + (5 * i) + 3);
    pianoMap.push(i * 7 + 5);
    pianoMap.push(firstBlackKeyIndex + (5 * i) + 4);
    pianoMap.push(i * 7 + 6);
}
pianoMap.push((numPianoOctaves) * 7 );

const pianoMap2: number[] = [];

for(let i = 0; i < numPianoOctaves; i++){
    pianoMap2.push(i * 12 + 1);
    pianoMap2.push(i * 12 + 3);
    pianoMap2.push(i * 12 + 6);
    pianoMap2.push(i * 12 + 8);
    pianoMap2.push(i * 12 + 10);
}
for(let i = 0; i < numPianoOctaves; i++){
    pianoMap2.push(i * 12);
    pianoMap2.push(i * 12 + 2);
    pianoMap2.push(i * 12 + 4);
    pianoMap2.push(i * 12 + 5);
    pianoMap2.push(i * 12 + 7);
    pianoMap2.push(i * 12 + 9);
    pianoMap2.push(i * 12 + 11);
}

console.log("MAP: " + pianoMap2);
let pianoKeyWidth: number = two.width/(firstBlackKeyValZoom + 2);

//White Keys
for(let i = 0; i < firstBlackKeyIndex; i++){
    pianoKeys.push(new WShape(
        two.makeRoundedRectangle(
            (-two.width/2) + (i *  (two.width/firstBlackKeyValZoom) + (two.width/((firstBlackKeyValZoom-1) *2) )),    
            whiteKeysY,
            pianoKeyWidth,
            pianoHeight,
            5)//rounded corners
        )
    );
}
//Black Keys
for(let i = 0; i < firstBlackKeyIndex - 1; i++){
    let j = i % 7;
    if(j != 2 && j != 6){
        pianoKeys.push(new WShape(two.makeRoundedRectangle(
            (-two.width/2) + (i *  (two.width/firstBlackKeyValZoom) + (two.width/(firstBlackKeyValZoom-1))), 
            whiteKeysY - ((pianoHeight * (1 - blackKeyHeightProportion))/2), 
            pianoKeyWidth * 0.8,  
            blackKeyHeight, 
            3
        )));
    }
} 
//Assign Piano Key Colors
for(let i = 0; i < (numPianoOctaves * 12); i++){
    if(i < firstBlackKeyIndex){
        pianoKeys[i].fill = 'rgba(255,255,255,.17)';
    }else{
        pianoKeys[i].fill = 'rgba(2,2,2,1)';
        
    }
    pianoKeys[i].shape.noStroke();
}
console.log("keyslength: " + pianoKeys.length);

var currPianoNote: number = 1;
var prevPianoNote: number = 0;


var pianoKeyPressed = false;

var currentPlace: number = 0;
/*const beats: WShape[] = [];
const beatHeight: number = two.height * 0.36;

for(let i = 0; i < 16; i++){
    beats.push(
        new WShape(
            two.makeCircle(i * (two.width * 0.02),beatHeight, 3)
        )
    );
    beats[i].fill = "rgba(255, 255, 255, 0.87)";
    beats[i].shape.noStroke();

}*/

//keyboard.InsertChildren(pianoKeys);
const playButton: Polygon = new Two.Polygon(0, 0, 30,3);
playButton.rotation = -0.53;
playButton.opacity = styles.opacity;
var isPlaying: boolean = false;//Is Tone Transport playing?
var globalPlaybackSpeed: number = 1;
var globalBPM: number = 120;
var updateBeat1:boolean = false;
var isRecordingChords: boolean = false;
var performance: number[][] = [];//ticks, on, note, velocity
var lastPerformance: number[][] = [];
//var currStrongTick: number = 0;
//var nextStrongTick: number = 0;

//console.log("TEST" + keysDOMRects[85].right);
two.add(playButton);
two.add(labelCircle);
two.add(innerLabelCircle);
two.add(centerText);
two.add(keyText);
//two.add(mainNoteArea);
//two.add(chordRootCircle.shape);
//two.add(chordCircle.shape);
//two.add(detChCircle.shape);
//two.add(detChRootCircle.shape);
two.add(letters);
two.add(innerLabelChars);
two.add(keySprite);
rings.forEach(e => {
    //two.add(e.shape);
});
pianoKeys.forEach(e => {
    two.add(e.shape);
});
/*beats.forEach(e => {
    two.add(e.shape)
});*/

//two.add(testRect.shape);

setInnerLabels(0, [0,0,0,0,0,0,0,0,0,0,0,0]);

two.bind('resize', resize)
    .bind('update', update);
resize();

const bassline: any[][] = [
    [51, true, "1:0:0"],
    [54, true, "1:0:0"],
    [58, true, "1:0:0"],
    
    [51, false, "1:1:2"],
    [54, false, "1:1:2"],
    [58, false, "1:1:2"],

    [47, true, "1:1:2"],   
    [51, true, "1:1:2"],
    [54, true, "1:1:2"],

    [47, false, "2:0:0"],   
    [51, false, "2:0:0"],
    [54, false, "2:0:0"],

    [42, true, "2:0:0"],
    [46, true, "2:0:0"],
    [49, true, "2:0:0"],
    
    [42, false, "2:1:2"],
    [46, false, "2:1:2"],
    [49, false, "2:1:2"],

    [44, true, "2:1:2"],
    [47, true, "2:1:2"],
    [51, true, "2:1:2"],

    [44, false, "3:0:0"],
    [47, false, "3:0:0"],
    [51, false, "3:0:0"],

    [51, true, "3:0:0"],//2
    [54, true, "3:0:0"],
    [58, true, "3:0:0"],
    
    [51, false, "3:1:2"],
    [54, false, "3:1:2"],
    [58, false, "3:1:2"],

    [47, true, "3:1:2"],   
    [51, true, "3:1:2"],
    [54, true, "3:1:2"],

    [47, false, "4:0:0"],   
    [51, false, "4:0:0"],
    [54, false, "4:0:0"],

    [42, true, "4:0:0"],
    [46, true, "4:0:0"],
    [49, true, "4:0:0"],
    
    [42, false, "4:1:2"],
    [46, false, "4:1:2"],
    [49, false, "4:1:2"],

    [44, true, "4:1:2"],
    [47, true, "4:1:2"],
    [51, true, "4:1:2"],

    [44, false, "5:0:0"],
    [47, false, "5:0:0"],
    [51, false, "5:0:0"],

    [51, true, "5:0:0"],//
    [54, true, "5:0:0"],
    [58, true, "5:0:0"],
    
    [51, false, "5:1:2"],
    [54, false, "5:1:2"],
    [58, false, "5:1:2"],

    [47, true, "5:1:2"],   
    [51, true, "5:1:2"],
    [54, true, "5:1:2"],

    [47, false, "6:0:0"],   
    [51, false, "6:0:0"],
    [54, false, "6:0:0"],

    [42, true, "6:0:0"],
    [46, true, "6:0:0"],
    [49, true, "6:0:0"],
    
    [42, false, "6:1:2"],
    [46, false, "6:1:2"],
    [49, false, "6:1:2"],

    [44, true, "6:1:2"],
    [47, true, "6:1:2"],
    [51, true, "6:1:2"],

    [44, false, "7:0:0"],
    [47, false, "7:0:0"],
    [51, false, "7:0:0"],

    [51, true, "7:0:0"],//2
    [54, true, "7:0:0"],
    [58, true, "7:0:0"],
    
    [51, false, "7:1:2"],
    [54, false, "7:1:2"],
    [58, false, "7:1:2"],

    [47, true, "7:1:2"],   
    [51, true, "7:1:2"],
    [54, true, "7:1:2"],

    [47, false, "8:0:0"],   
    [51, false, "8:0:0"],
    [54, false, "8:0:0"],

    [42, true, "8:0:0"],
    [46, true, "8:0:0"],
    [49, true, "8:0:0"],
    
    [42, false, "8:1:2"],
    [46, false, "8:1:2"],
    [49, false, "8:1:2"],

    [44, true, "8:1:2"],
    [47, true, "8:1:2"],
    [51, true, "8:1:2"],

    [44, false, "9:0:0"],
    [47, false, "9:0:0"],
    [51, false, "9:0:0"]



];

var startTime: number;
var currTime: number = 0;
var prevTime: number = 0;
var barMs : number = 0;//measures number of milliseconds elapsed since last bar line
var tempoMapMs: number[] = [];//for recording tempo maps
var prevTempoMapMs: number[] = [];//[2105, 3076, 4728, 7096, 10713, 13692, 16233, 17944, 20160, 22104, 23968, 25861, 27713, 29576];//test
var recordedChordProgression : string[][] = [];
var prevRecordedChordProgression : string[][] = [];
var videoPlayOffset: number = 0;

var ryuichi: WSong = new WSong("Tong Poo", "Ryuichi Sakamoto","Ryuichi Sakamoto",'dRokdqRtles',6.25,100,168, 
    "",//MIDI file
    [
        1469,1367,1472,1472,1480,1458,1422,1496,1528,1488,1424,1477,1483,1466,1486,1464,
        1424,1488,1472,1472,1472,1473,1452,1500,1514,1445,1464,1449,1479,1488,1449,1464,
        1512,1488,1440,1488,1488,1464,1481,1439,1488,1440,1466,1486,1472,1472,1480,1480,
        1488,1456,1496,1440,1515,1445,1488,1456,1480,1448,1465,1495,1472,1488,1464,1456,
        1442,1502,1504,1461,1451,1512,1440,1456,1504,1464,1472,1504,1456,1464,1504,1432,
        1520,1464,1448,1448,1496,1488,1488,1440,1481,1495,1464,1457,1463,1473,1471,1488,
        1472,1472,1472,1416,1504,1488,1457,1504,1464,1458,1502,1448,1471,1433,1488,1492,
        1476,1464,1480,1456,1496,1512,1416,1504,1464,1464,1450,1462,1504,1480,1432,1505,
        1487,1464,1440,1480,1464,1498,1486,1514,1454,1416,1504,1480,1456,1448,1464,1480,
        1456,1518,1458,1496,1440,1513,1471,1432,1464,1504,1472,1491,1485,1432,1483,1485,
        1616,2232,2040
    ],[
        ["C","maj7","B", "1:0:0"],
        ["A","m","", "2:0:0"],
        ["D", "m","","3:0:0"],
        ["F", "maj","","4:0:0"]
    ],[[4,"0:0:0"]],[["D","m", "1:0:0"]]
);

var blankSong: WSong = new WSong("Blank Song", "DRektX","DRektX",'',6.25,100,60,
    "",
    [
        
    ],[
        
    ],[[4,"0:0:0"]],[["D","m", "0:0:0"]]
);

var foreverYoung: WSong = new WSong("Forever Young", "Alphaville","Alphaville",'oNjQXmoxiQ8',1,100,120,
    "",
    [
        1200
    ],[
        ["C","maj7","B", "1:0:0"],
        ["A","m","", "2:0:0"],
        ["D", "m","","3:0:0"],
        ["F", "maj","","4:0:0"]
    ],[[4,"0:0:0"]],[["D","Minor", "0:0:0"]]
);

var fanfare: WSong = new WSong("Fanfare", "Tine Thing Helseth","Kjetil Bjerkestrand",'BbzvvwIFXF0',10,100,75,
    "./fanfare.mid",
    [
        7000, 4538, 4187, 3901, 4048, 3875, 4093, 4168, 4440, 4016, 4001, 4023, 4194, 4294, 4208, 4376, 4400, 4224, 3928, 3864, 4168, 4000, 4232, 3960, 4592, 5696, 4627, 5405
    ],[
        ["Bb","maj","", "1:0:0"],
        ["F","maj","", "2:2:0"],
        ["Eb","maj","", "3:0:0"],

        ["Bb","maj","", "4:0:0"],
        ["Eb","maj","", "4:2:0"],
        ["F","sus4","", "5:0:0"],
        ["F","maj","", "5:2:0"],

        ["Bb","maj","", "6:0:0"],
        ["Eb","maj","G", "6:2:0"],
        ["D", "maj", "F#", "7:0:0"],
        ["G", "m", "", "7:2:0"],

        ["Eb","maj","", "8:0:0"],
        ["Bb","add2","", "8:2:0"],
        ["Bb","maj","", "8:3:0"],
        ["C","min7","", "9:0:0"],
        ["F", "maj", "", "9:2:0"],

        ["Bb","maj","", "10:0:0"],
        ["F", "maj", "A", "10:2:0"], 
        ["Eb","maj","G", "11:0:0"],
        ["Bb","maj", "", "11:2:0"],

        ["Eb","maj","", "12:0:0"],
        ["Bb","maj","D", "12:2:0"],
        ["Eb","maj","", "13:0:0"],
        ["F", "maj", "A", "13:2:0"],

        ["C", "maj", "E", "14:0:0"],
        ["F", "maj", , "14:2:0"],
        ["D", "maj", "F#", "15:0:0"],
        ["G", "min", "","15:2:0"],

        ["Eb", "maj", "G", "16:0:0"],
        ["F", "sus4","", "17:0:0"],
        ["F", "maj","", "17:2:0"],


        ["Bb","maj","", "18:0:0"],
        ["F","maj","", "18:2:0"],
        ["","","", "19:0:0"],
        ["Eb","maj","", "19:1:0"],
        ["Bb","maj","", "19:2:0"],

        ["Eb","maj","", "20:0:0"],
        ["Bb","maj","D", "20:2:0"],
        ["Eb","maj","G", "21:0:0"],
        ["F","sus4","", "21:2:0"],
        ["F","maj","", "21:3:0"],

        ["Bb","maj","", "22:0:0"],
        ["F", "maj", "C", "22:2:0"],
        ["Eb","maj","", "23:0:0"],
        ["Bb","maj","", "23:2:0"],

        ["Eb","maj","", "24:0:0"],
        ["Bb","maj","D", "24:2:0"],
        ["C", "min7", "", "25:0:0"],
        ["F", "maj", "", "25:2:0"],
        ["Bb","maj","", "26:0:0"],


    ],[[4,"0:0:0"]],[["Bb","Major", "0:0:0"]]
);

const bach: WSong = new WSong("Bach Three-Part Invention", "Bach","Bach",'6JMTzXJCuVU',0.7,1000,100,
    "./bach.mid",
    [
        2642, 2552, 2588, 2660, 2552, 2673, 2575, 2596, 2661, 2736, 2639, 2643, 2566, 2650, 2525, 2576, 2688, 2624, 2601, 3209, 3209
    ],[
        ["C","maj","", "1:0:0"],
        ["D","maj","", "2:3:0"],
        ["G","maj","", "3:0:0"],
        ["C","maj","E", "6:0:0"],
        ["F","maj","", "6:2:0"],
        ["C","maj","", "7:0:0"],
        ["D","maj","", "7:2:0"],
        ["G","maj","B", "9:2:0"],
        ["C","maj","", "10:0:0"],

        ["A","maj","", "12:0:0"],
        ["D","m","", "13:0:0"],
        
        ["G","m", "","14:0:0"],
        ["","", "","15:0:0"],
        ["C","maj","", "17:0:0"],
        ["G","maj","", "20:2:0"],
        ["C","maj","", "21:0:0"],
    ],[
        [4,"0:0:0"]
        ,[[3,4],"1:0:0"]
    
    ],
    [//Keys
        ["C","Major", "0:0:0"],
        ["G","Major", "2:3:0"],
        ["C","Major", "3:0:0"],
        ["C","Major", "7:0:0"],
        ["G","Major", "7:2:0"],
        ["D","Melodic Minor", "12:0:0"],
        ["D","Minor", "13:0:0"],
        
        ["G","Harmonic Minor", "13:2:0"],
        
        ["C","Major", "14:0:0"],
        //["F","Major", "17:0:0"],
        ["C","Major", "20:0:0"],
    ]
);

var currSong: WSong = bach;
loadSong(currSong);

//prevTempoMapMs = ryuichi.tempoMap;
//setTempoMap();

Tone.Transport.setLoopPoints("1:0:0", "3:0:0");
//Tone.Transport.loop = true;

function resize() {
    
    two.scene.position.set(two.width / 2, two.height / 2);
    two.width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    two.height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight; 
    //outsideRadius = Math.min(two.width, two.height) * 0.3;
    //insideRadius = Math.min(two.width, two.height) * 0.1;


    pianoHeight = (((two.height/2) - outsideRadius)/5) * 2;
    blackKeyHeight = pianoHeight * blackKeyHeightProportion;
    setPianoZoom(numOctaves, octave,numSteps);
    console.log("resized: " + two.width);
    //two.renderer.setSize(width, height);
}

function update(frameCount: number, timeDelta: number) {
    

    var sLabel: number;
    var labelRotMovement: number;
    var keyRotMovement: number;
    var keySpriteMovement: number;
    var rotLabels: number;
    //console.log(currentPlace);
    //if(isPlaying)
        //console.log(Tone.Transport.position);
    //console.log(isVideoPlaying);
    //Pitch Bend Wheel Rotation
    labelRot += wheelPitchBend;
    keyRot += wheelPitchBend;

    labelRotMovement = ((labelRot) - labelCircle.rotation) * drag;
    
    keyRotMovement = (keyRot - keyCircle.rotation) * drag;
    keySpriteMovement = keyRotMovement;
    
    labelCircle.rotation += labelRotMovement;
    innerLabelCircle.rotation += labelRotMovement;
    
    keyCircle.rotation += keyRotMovement;
    mainNoteArea.rotation = keyCircle.rotation;
   
    chordRootCircle.rotation = mainNoteArea.rotation;
    chordCircle.rotation = mainNoteArea.rotation;

    keyPosition += (keySpriteMovement / TWO_PI);//Problem
    keySprite.position.x = (keyRadius) * Math.sin(keyPosition * TWO_PI);
    keySprite.position.y = - (keyRadius) * Math.cos(keyPosition * TWO_PI); 

    for (var i = 0; i < numSteps; i++) {
        //letterPositions[i] += 0.001;
        letterPositions[i] += (labelRotMovement / TWO_PI);
        letters[i].position.x = outsideRadius * Math.sin(letterPositions[i] * TWO_PI);
        letters[i].position.y = - outsideRadius * Math.cos(letterPositions[i] * TWO_PI);
        
        innerLabelCharPositions[i] += (labelRotMovement / TWO_PI);
        innerLabelChars[i].position.x = insideRadius * Math.sin(innerLabelCharPositions[i] * TWO_PI);
        innerLabelChars[i].position.y = - insideRadius * Math.cos(innerLabelCharPositions[i] * TWO_PI);
    }

    mainNoteArea.update();
    detChCircle.update();
    detChRootCircle.update();
    //note rings follow labelRot
    for (var i = 0; i < (9 * numSteps); i++) {
        rings[i].rotation += labelRotMovement;
    }
    rings.forEach(wnote => {
        wnote.update();
    });
    //detChCircle.rotation += (labelRot - detChCircle.rotation) * drag;
    detChCircle.rotation += labelRotMovement;
    detChRootCircle.rotation = detChCircle.rotation;

    centerText.value = chordName;//update center chord text

    //Experiment for Pitch Bend
    //pitchShift.pitch = (pitchShift.pitch - pitchShiftTarget) * drag;
    //currentTime += timeDelta;
}

/***************************************************
* UI EVENT LISTENERS
***************************************************/

muteBTN.addEventListener("click", () => {
    //console.log("Mute Button Clicked");
    
    if (Tone.context.state != "running") {
        Tone.start();
        muteBTN.textContent = "Audio ON";
    }
});

analyzeBTN.addEventListener("click", () => {
    //let context: AudioContext = new window.AudioContext();
    analyzeBTN.textContent = "ANALYZING";
    audioToNoteEvents("./checkMono.wav", mySuccessCallback, myFailureCallback, myProgressCallback  );
    //sampler.triggerAttackRelease(["C2", "E2", "G2", "B2"], 1);
});

scaleSelector.addEventListener("change", () => {
    //console.log("Changed" + scaleSelector.value);
    setMainNoteAreaScale(scaleSelector.value);
    currScale = scaleSelector.value;
    //mainNoteArea.startFlashing(/*Target*/[0, 0, 255, .17], /*Flash*/[0,0, 255,100], 800);
    setKeyText();
    //keyText.value = "KEY: " + noteLetters[noteLetterType][(currKey + 3) % numSteps] + " " + scaleSelector.options[currScale].text;

});

function setSharpLabels(){
    sharpNotes = true;
    noteLetterType = 1;
    setLabels([1,1,1,1,1,1,1,1,1,1,1,1]);
    for(let i = 0; i < 12; i++){
        letters[i].value = noteLetters[noteLetterType][i];
    }
}

function setFlatLabels(){
    sharpNotes = false;
    noteLetterType = 2;
    setLabels([1,1,1,1,1,1,1,1,1,1,1,1]);
    for(let i = 0; i < 12; i++){
        letters[i].value = noteLetters[noteLetterType][i];
    }
}

labelSelector.addEventListener("change", () => {
    //console.log("Changed" + scaleSelector.value);
    switch(parseInt(labelSelector.value)){
        case 1://sharp labels
            setSharpLabels();
            break;
        case 2://flat labels
            setFlatLabels();
            break;

        case 8://for scale labels
        break;
        case 9:
            for(let i = 0; i < 12; i++){
                letters[i].value = noteLetters[1][i];
            }
            setLabels(chords.getTriad(currChordOffset, currChord));
        break;
        default:
            setLabels([1,1,1,1,1,1,1,1,1,1,1,1]);
            for(let i = 0; i < 12; i++){
                letters[i].value = noteLetters[labelSelector.value][i];
            }
        break;
    }
    setKeyText();

});

octaveSelector.addEventListener("change", () => {
    numOctaves = parseInt(octaveSelector.value);
    if(octave + numOctaves > 8){
        let octShift = octave + numOctaves - 8;
        octave -= octShift;
        
    }
    octLimit = 9 - numOctaves;
    setWheelZoom(numOctaves, 0, numSteps);
    
    setPianoZoom(numOctaves, 0, numSteps);
    panic();
    console.log("NUM OCTAVES: " + numOctaves + ", octave: " + octave);
    
});

autoRotationSelector.addEventListener("change", () => {
    //console.log("Changed" + scaleSelector.value);
    autoRotateSetting = parseInt(autoRotationSelector.value);
    console.log("auto Rotate setting: " + autoRotateSetting);
});

chordDetectBTN.addEventListener("click", () => {
    //console.log("Mute Button Clicked");
    
    chordDetection = !chordDetection;
    resetChordDetect();
    if(chordDetection == true){
        chordDetectBTN.textContent = "Ch.Detection ON";
    }else{
        chordDetectBTN.textContent = "Ch.Detection OFF";
    }
        
    
});



//HOVERING OVER SOMETHING
two.renderer.domElement.addEventListener('mouseover', function(e) {
    let rect: DOMRect = letters[1].getBoundingClientRect();
    let x = e.clientX;
    var y = e.clientY;
    
    
    //if clicked over letter:
    //console.log("L: " + rect.left + ", R: " + rect.right + ", Top: " + rect.top + ", Bot: " + rect.bottom);
    /*if (x >= rect.left && x <= rect.right && y >= rect.top - 50 && y <= rect.bottom +100) {
      letters[1].fill = 'rgba(0, 255, 0, 1)';
    }else{
        //myPoints[0].fill = 'rgba(255, 255, 255, 0.2)';
        letters[1].fill = 'white';
    }*/


    
}, false);

//CLICKING & HOLDING DOWN ON SOMETHING
two.renderer.domElement.addEventListener('mousedown', function(e){
    uiStart(e);
});

//Mouse move
window.addEventListener('mousemove', function(e){//For rotating wheels
    uiMove(e);
});

window.addEventListener('mouseup',function(e){//mouse released
    uiEnd(e);
});

two.renderer.domElement.addEventListener('touchstart', function(e){
    touch2Mouse(e);
},true);
window.addEventListener('touchmove', function(e){
    touch2Mouse(e);
}, true);
window.addEventListener('touchend', function(e){
    touch2Mouse(e);
},true);

/*two.renderer.domElement.addEventListener('click', function(e) {
    for(let i = 0; i < numSteps; i++){
        let rect: DOMRect = letters[i].getBoundingClientRect();
        let x = e.clientX;
        var y = e.clientY;
        //if clicked over letter:
        if (x >= rect.left && x <= rect.right && y >= rect.top && y <= rect.bottom+100) {

            if(i < (wheelRotationValue % numSteps) - 6){
                wheelRotationTurns += 1;
                
            }else if(i > (wheelRotationValue % numSteps) + 6){//reverse
                wheelRotationTurns -= 1;
            }
            
            wheelRotationValue = i + (wheelRotationTurns * 12);   
            
        }
    }
    //prevWheelRotationValue = wheelRotationValue;
    //console.log("wheelRotationValue: " + wheelRotationValue % 12);
    //console.log("wheelRotationTurns: " + wheelRotationTurns);

}, false);*/

const toggleVisibility = () => {
    const elementsToHide = document.querySelectorAll('label, select, button');
    elementsToHide.forEach((element) => {
        const htmlElement = element as HTMLElement;
        htmlElement.style.display = htmlElement.style.display === 'none' ? 'inline' : 'none';
    });
};

window.addEventListener('keydown', function(e){
    //const theKey = e.key;
    var storedKey;
    if (e.repeat != undefined) {
        allowed = !e.repeat;
        storedKey = e;
    }
    if (!allowed) return;
    allowed = false;

    switch(e.key){
        case "ArrowLeft":
            totalRotation += -TWO_PI * (1 / 12);
            let nearestMultiple = calculateNearestMultiple(totalRotation);
            labelRot = nearestMultiple;
            keyRot -= keyRot - labelRot;
        break;
        case "ArrowRight":
            totalRotation -= -TWO_PI * (1 / 12);
            let rNearestMultiple = calculateNearestMultiple(totalRotation);
            labelRot = rNearestMultiple;
            keyRot += labelRot - keyRot;
        break;
        case "ArrowUp":
            currChordOffset++;
            currChordOffset %= 12;
            if(parseInt(labelSelector.value) == 9){
                setLabels(chords.getTriad(currChordOffset, currChord));
            }
            console.log("currScale: " + currScale);//3
            console.log("currChordOffset: " + currChordOffset)
            setChord(currChordOffset, scales.getScaleChords(1)[currChordOffset]);
            console.log("scales.get: " + scales.get(currScale)[currChordOffset]);

        break;
        case "ArrowDown":
            currChordOffset--;
            if(currChordOffset < 0){
                currChordOffset += 12;
            }
            if(parseInt(labelSelector.value) == 9){
                setLabels(chords.getTriad(currChordOffset, scales.get(currScale)[currChordOffset]));
            }
            setChord(currChordOffset, scales.getScaleChords(1)[currChordOffset]);
            console.log(currChordOffset);
        break;
        case 'Z':
        case "z":
            if(keyboardOctave > 1){
                keyboardOctave--;
                console.log("OCT: " + keyboardOctave);
                panic();
            } 
        break;
        case 'X':
        case "x":
            if(keyboardOctave < numOctaves){
                keyboardOctave++;
                console.log("OCT: " + keyboardOctave);
                panic();
            }
            break;
        case 'n':
        case "-":
            if(octave > 0){
                octave--;
                lowestNote = (octave * 12);
                highestNote = (octave * 12) + (numOctaves * 12);
                console.log("Piano Oct: " + octave + ": " + lowestNote + ", " + highestNote);
                panic();
            }
            break;
        case 'm':
        case "=":
            if(octave < octLimit - 1){
                octave++;
                lowestNote = (octave * 12);
                highestNote = (octave * 12) + (numOctaves * 12);
                console.log("Piano Oct: " + octave + ": " + lowestNote + ", " + highestNote);
                panic();
            }
            break;
        case 'b':
        case 'B':
            if(isPlaying == true){
                currTime = Date.now() - startTime;
                tempoMapMs.push(currTime - prevTime);
                console.log(tempoMapMs);
                prevTime = currTime;
            }
            
        break;
        case 'R':
            isRecordingChords = !isRecordingChords;
            console.log(isRecordingChords);
            resetChordDetect();
        break;
        case '[':
            
            backOneBar();
        break;
        case ']':
            forwardOneBar();
        break;
        case '`':
            toggleVisibility();

        break;
        case '1':
            globalPlaybackSpeed = 0.25;
            changePlaybackSpeed(0.25);

        break;
        case '2':
            globalPlaybackSpeed = 0.5;
            changePlaybackSpeed(0.5);
        break;
        case '3':
            globalPlaybackSpeed = 0.75;
            changePlaybackSpeed(0.75);
        break;
        case '4':
            globalPlaybackSpeed = 1;
            changePlaybackSpeed(1);
        break;
        case '5':
            globalPlaybackSpeed = 1.25;
            changePlaybackSpeed(1.25);
        break;
        case '6':
            globalPlaybackSpeed = 1.5;
            changePlaybackSpeed(1.5);
        break;
        case '7':
            globalPlaybackSpeed = 1.75;
            changePlaybackSpeed(1.75);
        break;
        case '8':
            globalPlaybackSpeed = 2;
            changePlaybackSpeed(2);
        break;

            
    } 

    

    let keyOct = 12 * keyboardOctave;
    for(let i = 0; i < keyboardArray.length; i ++){
        if(e.key == keyboardArray[i][0] || e.key == keyboardArray[i][1]){
            
            playNote(keyOct + i, keyboardVelocity, 1, false, true);
            if(chordDetection){
                chordDetectON(keyOct+i);
            }
            console.log("ON");
            if(isPlaying == true){
                addNoteToPerformance(Tone.Transport.ticks,1, keyOct + i, keyboardVelocity, 1);
            }
        }
    }

                
});

window.addEventListener('keyup', function(e){
    allowed = false;
    let keyOct = 12 * keyboardOctave;
    for(let i = 0; i < 19; i ++){
        if(e.key == keyboardArray[i][0] || e.key == keyboardArray[i][1]){
            stopNote(keyOct + i, 1, false, true);
            if(chordDetection){
                chordDetectOff(keyOct+i);
            }
            
            rings[keyOct+i-12].stop(1, keyOct+i);
            console.log("_");
            if(isPlaying == true){
                addNoteToPerformance(Tone.Transport.ticks, 0, keyOct + i, 0, 1);
            }
        }
    }
            
});


let currentlyPressedNotes: Set<number> = new Set();
let sustainedNotes: Set<number> = new Set();
let isSustainPedalDown: boolean = false;

function handleSustainPedal(value: number): void {
    isSustainPedalDown = value >= 64;
    if (!isSustainPedalDown) {
        sustainedNotes.forEach(noteNumber => {
            if (!currentlyPressedNotes.has(noteNumber)) {
                //sampler1.triggerRelease(Tone.Midi(noteNumber).toNote());
                sampler1.triggerRelease(Tone.Frequency(noteNumber, "midi").toNote());
            }
        });
        sustainedNotes.clear();
    }
}
/***************************************************
* WebMidi STUFF
***************************************************/
// Enable WebMidi.js and trigger the onEnabled() function when ready.

WebMidi
    .enable()
    .then(onEnabled)
    .catch(err => alert(err));

function onEnabled(): void {
    //myInputNum: number = 0;
    let chVal: number = 0;
    if (WebMidi.inputs.length < 1) {
        document.body.innerHTML += "No device detected.";
    } else {
        WebMidi.inputs.forEach((device, index) => {
            //document.body.innerHTML+= `${index}: ${device.name} <br>`;

            console.log(index + " " + device.name);

            /*var opt = document.createElement("option");
            opt.value = "" + index;
            opt.innerHTML = device.name;
            inputSelector.appendChild(opt);*/


            if(device.name == "loopMIDI Port" || device.name == "microKEY2 Air"){
                myInputNum = index;
                console.log("myInputNum: " + myInputNum);
                myInputs.push(index);
            }
        });
    }
    
    myMidiInput = WebMidi.inputs[myInputNum];
    // const mySynth = WebMidi.getInputByName("TYPE NAME HERE!")

    try {
        for(let k = 0; k < myInputs.length; k++){//loop through all inputs
            WebMidi.inputs[myInputs[k]].channels.forEach((i, index) => {//loop through each channel per input
    
                if(k == 0 || index == 1){
                    WebMidi.inputs[myInputs[k]].channels[index].addListener("noteon", e => {
                        //let note = e.note.number - 12;
                        
                            if (Tone.context.state != "running") {
                                Tone.start();
                            }
                            if(index != detectionChannel){
                                //console.log("velocity:" + e.note.rawAttack);
                                currentlyPressedNotes.add(e.note.number);
                                if (isSustainPedalDown) {
                                    sustainedNotes.add(e.note.number);
                                }
                                playNote(e.note.number, e.note.rawAttack, index, false, true);
                                if(chordDetection){
                                    chordDetectON(e.note.number);
                                }
                                //rings[e.note.number].startFlashing([index * 70, 100, (e.note.number * 0.8 + 10), 1],[0,0,100,1],200);
                                //rings[e.note.number].shake(2,700);
            
                            }else{//if detection channel
                                if(e.note.number < 12){
                                    setKey(e.note.number);
                                }else{
                                    chordDetectON(e.note.number);
                                }
                                
                                
                            }
                        
                        
                        
                    });
        
                    WebMidi.inputs[myInputs[k]].channels[index].addListener("noteoff", e => {
                        //let note = e.note.number - 12;
                        //let rem: number = currentNotes.indexOf(e.note.number); 
                        if(index != detectionChannel){//if channel is not 16
        
                            stopNote(e.note.number, index, false, true);
                            if(chordDetection){
                                chordDetectOff(e.note.number);
                            }
                            
                        }else{
                            chordDetectOff(e.note.number);
                        }
        
                    });
        
                    WebMidi.inputs[myInputs[k]].channels[index].addListener("pitchbend", e => {
                        //  console.log("cc#: " + e.controller.number +" "+ e.rawValue);
                        if(index != detectionChannel){//if channel is not 16
                            //pitchShift.pitch = (e.rawValue - 8192) * 0.0029300452;
                            //pitchShiftTarget = ((e.rawValue - 8192) * 0.0029300452);
                            
                                //console.log("PB TARGET:  " + e.target.number);
                            
                        }else{
                            wheelPitchBend = (e.rawValue - 8192) * 0.00002;
                            if(wheelPitchBend == 0){
                                //if(wheelModCC < 64){//PB LABEL rotation release
                                    
                                    labelReleased();
                                /*}else{//PB KEY rotation release
                                    keyRot = calculateNearestMultiple(keyRot);
                                    currKeyPosition = calculatePosition(keyRot);
                                    currKey = setCurrKey(currLabelPosition, currKeyPosition);
                                    keyText.value = noteLetters[1][(currKey + 3) % numSteps] + " " + scaleSelector.options[currScale].text;
                                    //reset currKey
                                }*/
                            }
                        }
                        
                    });
        
                    WebMidi.inputs[myInputs[k]].channels[index].addListener("controlchange", e => {
                        //console.log("cc#: " + e.controller.number +" "+ e.rawValue + " " + (index -1 ));
                        if(index != detectionChannel){
                            switch(e.controller.number){
                                case 1:
                                    wheelModCC = e.rawValue;
                                    console.log("X");
                                break;
                                case 64:
        
                                        handleSustainPedal(e.rawValue);
                                    
                                    /*if (e.rawValue > 0)  {
                                        sustain[index-1] = true;
                                    }else{
                                        sustain[index-1] = false;
                                        sustainingNotes[index-1].forEach(n => {
                                            rings[n].visible = false;
                                            sampler1.triggerRelease(Tone.Frequency(n, "midi").toNote());
                                            
                                        });
                                        sustainingNotes[index-1].length = 0;
                                    }*/
                                    //console.log("SUSTAIN: "  + sustain);
                                break;
            
                            }
                        }else{
                            
                        }
                        
                    });
        
                    WebMidi.inputs[myInputs[k]].channels[index].addListener("programchange", e => {
                        //  console.log("cc#: " + e.controller.number +" "+ e.rawValue);
                        if(index != detectionChannel){
                            
                        }else{
                            //console.log("PC: " + e.rawValue);
                            setMainNoteAreaScale(e.rawValue);
                            currScale = scales.getScaleName(e.rawValue); //e.rawValue;
                            scaleSelector.selectedIndex = e.rawValue;
                            // = "KEY: " + noteLetters[noteLetterType][(currKey + 3) % numSteps] + " " + scaleSelector.options[currScale].text;
                            setKeyText();
        
                        }
                        
                    });
                }
                
            });
    
        }    
        
    } catch (error) {
        //console.error(error);
        // Expected output: ReferenceError: nonExistentFunction is not defined
        // (Note: the exact output may be browser-dependent)
    }

}

/*const limiter: Tone.Limiter = new Tone.Limiter(-15);
var vol = new Tone.Volume(-1);
var myReverb: Tone.Reverb = new Tone.Reverb(4);
myReverb.wet.value = 0.3;
const pitchShift: Tone.PitchShift = new Tone.PitchShift(5);
const sampler: Tone.Sampler = new Tone.Sampler({
        urls: {
            A0: "A0.mp3",
            C1: "C1.mp3",
            "D#1": "Ds1.mp3",
            "F#1": "Fs1.mp3",
            A1: "A1.mp3",
            C2: "C2.mp3",
            "D#2": "Ds2.mp3",
            "F#2": "Fs2.mp3",
            A2: "A2.mp3",
            C3: "C3.mp3",
            "D#3": "Ds3.mp3",
            "F#3": "Fs3.mp3",
            A3: "A3.mp3",
            C4: "C4.mp3",
            "D#4": "Ds4.mp3",
            "F#4": "Fs4.mp3",
            A4: "A4.mp3",
            C5: "C5.mp3",
            "D#5": "Ds5.mp3",
            "F#5": "Fs5.mp3",
            A5: "A5.mp3",
            C6: "C6.mp3",
            "D#6": "Ds6.mp3",
            "F#6": "Fs6.mp3",
            A6: "A6.mp3",
            C7: "C7.mp3",
            "D#7": "Ds7.mp3",
            "F#7": "Fs7.mp3",
            A7: "A7.mp3",
            C8: "C8.mp3"
        },
        release: 1,
        baseUrl: "https://tonejs.github.io/audio/salamander/",
        onload: () => {
            //sampler.triggerAttackRelease(["C3", "E3", "G3", "B3"], 0.5);
        }
}).chain( myReverb, limiter, vol), channel1;
var pitchShiftTarget: number = 0;

var vol2 = new Tone.Volume(-30);
const sampler2: Tone.Sampler = new Tone.Sampler({
    urls: {
        C4: "Cooh.mp3"
    }, 
    release: 1,
    baseUrl: "./",
    onload: () => {
        //sampler.triggerAttackRelease(["C3", "E3", "G3", "B3"], 0.5);
    }
}).chain(vol2, merge);*/

// Initialize Sampler Instruments
const sampler1 = new Tone.Sampler({
    urls: {
        A0: "A0.mp3",
        C1: "C1.mp3",
        "D#1": "Ds1.mp3",
        "F#1": "Fs1.mp3",
        A1: "A1.mp3",
        C2: "C2.mp3",
        "D#2": "Ds2.mp3",
        "F#2": "Fs2.mp3",
        A2: "A2.mp3",
        C3: "C3.mp3",
        "D#3": "Ds3.mp3",
        "F#3": "Fs3.mp3",
        A3: "A3.mp3",
        C4: "C4.mp3",
        "D#4": "Ds4.mp3",
        "F#4": "Fs4.mp3",
        A4: "A4.mp3",
        C5: "C5.mp3",
        "D#5": "Ds5.mp3",
        "F#5": "Fs5.mp3",
        A5: "A5.mp3",
        C6: "C6.mp3",
        "D#6": "Ds6.mp3",
        "F#6": "Fs6.mp3",
        A6: "A6.mp3",
        C7: "C7.mp3",
        "D#7": "Ds7.mp3",
        "F#7": "Fs7.mp3",
        A7: "A7.mp3",
        C8: "C8.mp3"
    },
    release: 1,
    baseUrl: "https://tonejs.github.io/audio/salamander/"
  });
  
  const sampler2 = new Tone.Sampler({
    urls: {
        A0: "A0.mp3",
        C1: "C1.mp3",
        "D#1": "Ds1.mp3",
        "F#1": "Fs1.mp3",
        A1: "A1.mp3",
        C2: "C2.mp3",
        "D#2": "Ds2.mp3",
        "F#2": "Fs2.mp3",
        A2: "A2.mp3",
        C3: "C3.mp3",
        "D#3": "Ds3.mp3",
        "F#3": "Fs3.mp3",
        A3: "A3.mp3",
        C4: "C4.mp3",
        "D#4": "Ds4.mp3",
        "F#4": "Fs4.mp3",
        A4: "A4.mp3",
        C5: "C5.mp3",
        "D#5": "Ds5.mp3",
        "F#5": "Fs5.mp3",
        A5: "A5.mp3",
        C6: "C6.mp3",
        "D#6": "Ds6.mp3",
        "F#6": "Fs6.mp3",
        A6: "A6.mp3",
        C7: "C7.mp3",
        "D#7": "Ds7.mp3",
        "F#7": "Fs7.mp3",
        A7: "A7.mp3",
        C8: "C8.mp3"
    },
    release: 1,
    baseUrl: "https://tonejs.github.io/audio/salamander/"
  });
  
  // Create mixer channels
  const channel1 = new Tone.Channel();
  const channel2 = new Tone.Channel();
  
  // Connect samplers to mixer channels
  sampler1.connect(channel1);
  sampler2.connect(channel2);
  
  // Create a reverb bus
  const reverb = new Tone.Reverb({
    decay: 3,
    wet: 0.4
  });
  
// Connect channels to reverb and to the main output
channel1.connect(reverb);
channel2.connect(reverb);

// Create a limiter as the master effect
const limiter = new Tone.Limiter(-3).toDestination();
const masterVolume = new Tone.Volume(0);

// Connect channels and reverb bus to the limiter
//channel1.connect(limiter);
//channel2.connect(limiter);
reverb.connect(masterVolume);
masterVolume.connect(limiter);

// Control volume and panning (example values)
channel1.volume.value = 0;
channel1.pan.value = 0;
channel2.volume.value = 0;
channel2.pan.value = 0.25;
  


/***************************************************
* ToneJS MIDI File Parse
***************************************************/
// load a midi file in the browser
//const midi = await ToneMidi.fromUrl("path/to/midi.mid");

function incrementBars(timeString, increment = 1) {
    // Split the input string into its components
    let parts = timeString.split(':');
    
    // Increment the bar value by the specified increment
    let bar = parseInt(parts[0], 10) + increment;
    
    // Reconstruct the string with the incremented bar value
    return `${bar}:${parts[1]}:${parts[2]}`;
}

async function loadMidi(midiFileURL: string) {
    try {
        let myMidi = await ToneMidi.fromUrl(midiFileURL);
        
        // Check if the MIDI file was loaded correctly
        if (!myMidi) {
            throw new Error("Failed to load MIDI file.");
        }

        // Debugging: Log the entire MIDI object to inspect its structure
        console.log("Loaded MIDI object:", myMidi);

        
        const ticksToTime = (ticks: number) => {
            //const ppq = Tone.Transport.PPQ;
            const t = Tone.Ticks(ticks);
            return t.toBarsBeatsSixteenths() + "";
        };

        myMidi.tracks.forEach(track => {
            //tracks have notes and controlChanges
          
            //notes are an array
            track.notes.forEach(note => {
                const noteStart = ticksToTime(note.ticks);
                const noteEnd = ticksToTime(note.ticks + note.durationTicks);

                Tone.Transport.schedule((time) => {
                    playNote(note.midi, note.velocity * 127, track.channel, true, true);
                    //console.log("* Note ON: " + noteStart + " " + note.midi + ", " + track.channel);
                }, noteStart);

                Tone.Transport.schedule((time) => {
                    stopNote(note.midi, track.channel, true, true);
                    //console.log("* Note OFF: " + note.ticks + note.durationTicks + ", " + note.midi + ", " + track.channel);
                }, noteEnd);
            });
          
            //the control changes are an object
            //the keys are the CC number
            /*track.controlChanges[64]
            //they are also aliased to the CC number's common name (if it has one)
            track.controlChanges.sustain.forEach(cc => {
              // cc.ticks, cc.value, cc.time
              console.log(cc);
            });*/
          
            //the track also has a channel and instrument
            //track.instrument.name
        });

    } catch (error) {
        console.error("Error loading MIDI file:", error);
    }
}

function loadSong(song: WSong){
    let chProg = song.chordProgression;
    Tone.Transport.bpm.value = song.bpm;
    globalBPM = song.bpm;
    loadMidi(song.midiFile);

    setKey(Note.chroma(song.key[0][0]));

    //Key Changes
    for(let i = 0; i < song.key.length; i++){
        Tone.Transport.schedule(function(time){
            setKey(Note.chroma(song.key[i][0]));
            if(Note.accidentals(Note.get(song.key[i][0])) == 'b'){
                setFlatLabels();
            }else{
                setSharpLabels();
            }
            setMainNoteAreaScale(song.key[i][1]);
            currScale = song.key[i][1];
            setKeyText();
            
        }, song.key[i][2]);
    }

    // Time Signature
    if(song.timeSignature.length > 0){
        Tone.Transport.timeSignature = song.timeSignature[0][0];
        
        for(let i = 0; i < song.timeSignature.length; i++){
            console.log("Time Sig: " + song.timeSignature[i][0]);
            Tone.Transport.schedule((time) => {

                Tone.Transport.timeSignature = song.timeSignature[i][0];
                
            }, song.timeSignature[i][1]);
            
        }
    }
    //CHORD PROGRESSION
    for(let i = 0; i < chProg.length; i++){//for every chord
        //let temp = Chord.get(chordProgression[i][0]);
        let temp = Chord.getChord(chProg[i][1], chProg[i][0], chProg[i][2]);
        
        if(i>0){
            //for all notes in the previous chord
            let prev = Chord.getChord(chProg[i-1][1], chProg[i-1][0],chProg[i-1][2]);
            for(let j = 0; j < prev.notes.length; j++){
                let prevChordNote = Note.chroma(prev.notes[j]);
                Tone.Transport.schedule(function(time){
                    if(isRecordingChords == false){
                        if(j == 0){
                            chordDetectOff(prevChordNote + 48);
                        }else{
                            chordDetectOff(prevChordNote + 60);
                        }
                    }
                    
                }, chProg[i][3]);
            }
        }
        //console.log("TEST" + temp.intervals.length);
        let tempRoot = temp.tonic;

        //for all notes in current chord
        for(let j = 0; j < temp.notes.length; j++){
            let tempNote = Note.chroma(temp.notes[j]);
            Tone.Transport.schedule(function(time){
                    //playNote(bassline[i][0] + 12,keyboardVelocity,1);
                    if(isRecordingChords == false){
                        if(j == 0){
                            if(i>0){
                                chordDetectOff(Note.chroma(temp.notes[i - 1]));
                            }
                            chordDetectON(tempNote + 48);
                            
                        }else{
                            chordDetectON(tempNote + 60);
                        }
                    }
                        
                    //stopNote(bassline[i][0] + 12, 1);
                    //chordDetectOff(bassline[i][0]);
                //}
            }, chProg[i][3]);
        }
    }
    
    /*for(let i = 0; i < bassline.length; i++){
        Tone.Transport.schedule(function(time){
            //console.log("TIME REACHED");
            if(bassline[i][1] == true){
                //playNote(bassline[i][0] + 12,keyboardVelocity,1);
                chordDetectON(bassline[i][0]);
            }else{
                //stopNote(bassline[i][0] + 12, 1);
                chordDetectOff(bassline[i][0]);
                
            }
        }, bassline[i][2]);

    }*/

    Tone.Transport.schedule(function(time){//start video @ 1:0:0
        playVideo();
        player.seekTo(song.startTime,true);
        startTime = Date.now();
    }, "1:0:0");

    Tone.Transport.scheduleRepeat(function(time){//metronome ui
        //do something with the time
        if(currentPlace > (song.timeSignature[0][0] * 4)-1){
            currentPlace = 0;
        }
        if(currentPlace == 0){
            shakeWheel(75,160);
            detChCircle.startFlashing([184,100,69,0],[184,100,69,20],350);
            detChRootCircle.startFlashing([184,0,100,0],[184,0,100,40],350);
        }else if(currentPlace % 4  == 0){
            shakeWheel(20,200);
            detChCircle.startFlashing([184,100,69,0],[184,100,69,20],350);
            detChRootCircle.startFlashing([184,0,100,0],[184,0,100,40],350);
        }
        currentPlace += 1;
    }, "16n");

    //tempoMap
    if(song.tempoMap.length > 0){
        videoPlayOffset = song.tempoMap[0];//unnecessary?
    }
    if(song.tempoMap.length > 1){
        for(let i = 0; i < song.tempoMap.length; i++){
            //if(i > 0){
                Tone.Transport.schedule(function(time){
                    
                    barMs = Date.now();
                    Tone.Transport.bpm.value = (60000/ song.tempoMap[i]) * 4;
                    globalBPM = Tone.Transport.bpm.value;
                    Tone.Transport.bpm.value *= globalPlaybackSpeed;
                    console.log("Bar " + (i + 1));
                    
                }, (i+1) + ":0:0");

            //}
            
        }
    }
    
    if(song.tempoMap.length > 1){
        Tone.Transport.schedule(function(time){//start video @ 2:0:0
            isPlaying = false;
            stopVideo();
            Tone.Transport.stop();
            playButton.fill = styles.fill;
            playButton.opacity = styles.opacity;
            panic();
            resetChordDetect();
        }, /*song.end*/(song.tempoMap.length+1) + ":0:0");
    }

}

function setTempoMap(){
    
}

function loadLastPerformance(){
    for(let i = 0; i < lastPerformance.length; i++){
        Tone.Transport.scheduleOnce(function(time){
            //console.log("TIME REACHED");
            if(lastPerformance[i][1] > 0){
                playNote(lastPerformance[i][2], lastPerformance[i][3],1,false, true);
                //chordDetectON(performance[i][0]);
            }else{
                stopNote(lastPerformance[i][2], 1, false, true);
                //chordDetectOff(bassline[i][0]);
                
            }
        }, lastPerformance[i][0] + "i");

    }
}

/***************************************************
* OTHER FUNCTIONS
***************************************************/
function isMobileDevice() {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    return /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent);
}



function setKeyText(){
    keyText.value = "KEY: " + noteLetters[noteLetterType][(currKey + 3) % numSteps] + " " + currScale;
}

function setLabels(array: number[]){
    let labelArray: number[] = array;
    for(let i = 3; i < numSteps + 3; i++){
        if(labelArray[i - 3] == 1){
            letters[i % numSteps].visible = true;
        }else{
            letters[i % numSteps].visible = false;
        }
    }
}

function setInnerLabels(offset: number, array: number[]){
    let innerlabelArray: number[] = array;
    //set root of inner labels

    for(let i = 0; i < numSteps; i++){//make specific inner labels visible based on detected chord
        let temp = (i - offset + 21) % 12;
        //console.log("TEMP: " + temp);
        if(useRomanNumerals == true){
            innerLabelChars[i].value = romanNumerals.processInnerLabel(chordName, offset - currKey);
        }else{  
            innerLabelChars[i].value = chordLetters[2][temp];
        }
        
        
        if(innerlabelArray[temp] == 1){
            innerLabelChars[i].visible = true;
        }else{
            innerLabelChars[i].visible = false;
        }

        if(innerLabelChars[i].value != '1'){//set size of inner label chars
            innerLabelChars[i].size = innerStyle.size;
        }else{
            innerLabelChars[i].size = innerStyle.size * 2;
        }
    }
    
}

function setMainNoteAreaScale(myScale) {
    for (let i = 0; i < numSteps; i++) {
        if (scales.get(myScale)[i] == 1) {
            mainDashArray[i * 2] = mainDashes[1][0];
            mainDashArray[i * 2 + 1] = mainDashes[1][1];
        } else {
            mainDashArray[i * 2] = mainDashes[0][0];
            mainDashArray[i * 2 + 1] = mainDashes[0][1];
        }
    }
}

/*function setKey(){
    //setCurrKey function!!
    currKey = currKey + newKey;
    //MAKE INTO A FUNCTION FOR BACKEND CURRKEY
    currKey = (currKey + 3) % 12;
    if(currKey < 0){
        currKey = 12 + currKey;
    }
    console.log("CURRENT KEY: " + currKey);
}*/
function autoRotate(target: number){
    if(autoRotateSetting == 1){

    }else if(autoRotateSetting == 2){//follow detected chord root
        //currLabelPosition
    }
}

function setCurrKey(currLabelPos: number, currKeyPos: number){
    let newKey: number = currKeyPos - currLabelPos;
    newKey = newKey % 12;
    if(newKey < 0){
        newKey = 12 + newKey;
    }
    console.log("CURRKEY: " + newKey);
    return newKey;
}

function calculatePosition(temp: number){
    let myNewKey = Math.round(calculateNearestMultiple(temp) / (Math.PI / 6));
    return myNewKey;
}

function labelReleased(){
    labelRot = calculateNearestMultiple(labelRot);
    keyRot = calculateNearestMultiple(keyRot);
    currLabelPosition = calculatePosition(labelRot);
    currKeyPosition = calculatePosition(keyRot);
}

//up/down arrow keys chord
function setChord(offset: number, type: number | string){
    //Get Dash for Chord Root Circle!
    let root : number[] = chords.getRoot(offset);
    let chord : number[] = chords.getTriad(offset, type);
    console.log("setChord() run");
    console.log("offset: " + offset + ", type: " + type);
    console.log("chord: " + chord);

    for(var i = 0; i < numSteps; i++){
        if(root[i] == 1){
            chordRootDashArray[i * 2] = mainDashes[ON][0]; 
            chordRootDashArray[i * 2 + 1] = (mainDashes[ON][1]);
        }else{
            chordRootDashArray[i * 2] = mainDashes[OFF][0]; 
            chordRootDashArray[i * 2 + 1] = mainDashes[OFF][1];
        }
    
        if(chord[i] == 1){
            chordDashArray[i * 2] = mainDashes[ON][0]; 
            chordDashArray[i * 2 + 1] = mainDashes[ON][1];
        }else{
            chordDashArray[i * 2] = mainDashes[OFF][0]; 
            chordDashArray[i * 2 + 1] = mainDashes[OFF][1];
        }
    }
    chordRootCircle.dash = chordRootDashArray;
    chordCircle.dash = chordDashArray;  
    detChCircle.dash = chordDashArray;
    detChRootCircle.dash = chordRootDashArray;
}


//offset: sets the root value
//root is the root of the key
//noteArray
function setChordManually(offset: number, root: number, noteArray: number[]){
    //Get Dash for Chord Root Circle!
    //let root : number[] = chords.getRoot(offset);
    //let chord : number[] = chords.getTriad(offset, type);
    if(root < 0){
        for(let i = 0; i < numSteps; i++){
            chordRootDashArray[i * 2] = mainDashes[OFF][0]; 
            chordRootDashArray[i * 2 + 1] = (mainDashes[OFF][1]);
            chordDashArray[i * 2] = mainDashes[OFF][0]; 
            chordDashArray[i * 2 + 1] = (mainDashes[OFF][1]);
        }
    }else{
        for(var i = 0; i < numSteps; i++){
            if(i == root + offset){
                chordRootDashArray[i * 2] = mainDashes[ON][0]; 
                chordRootDashArray[i * 2 + 1] = (mainDashes[ON][1]);
            }else{
                chordRootDashArray[i * 2] = mainDashes[OFF][0]; 
                chordRootDashArray[i * 2 + 1] = mainDashes[OFF][1];
            }
        }
        
        for(let i = 0; i < noteArray.length; i++){
            let temp = (noteArray[i] + offset) % 12;
            chordDashArray[temp * 2] = mainDashes[ON][0]; 
            chordDashArray[temp * 2 + 1] = mainDashes[ON][1];
            
        }
    }

    chordRootCircle.dash = chordRootDashArray;
    chordCircle.dash = chordDashArray;  
    detChCircle.dash = chordDashArray;
    detChRootCircle.dash =chordRootDashArray;
}

function setWheelZoom(numOcts: number, oct: number, numSteps){
    var ringRadii:number[] = [];//array stores the radii of each octave circle
    var ringRadiiHalf:number[] = [];// used to calcultae ring dashes
    ringFit = mainNoteArea.linewidth / numOcts;//7 octaves within the main note area
    var ringDashes: number[][][] = [];//sizing of dashes
    var ringDashArrays: number[] = [];//change this to update note on/off
    for (var i = 0; i < 9; i++) {//Calculates the radius of each ring
        //                                                       offset         offset
        ringRadii.push((i * ringFit) + mainRadiusInnerOffset + (ringFit / 2));
    }
    for (var i = 0; i < 9; i++) {
        ringRadiiHalf.push(ringRadii[i] * 0.5);
    }
    /*for (var i = 0; i < 9; i++) {//Calculates the radius of each ring
        //   
        ringRadii.push((i * ringFit) + mainRadiusInnerOffset + (ringFit / 2));                                                    offset         offset
        //ringRadii[i] = (i * ringFit) + mainRadiusInnerOffset + (ringFit / 2);
        ringRadiiHalf[i] = ringRadii[i] * 0.5;
    }*/
    for (var i = 0; i < 9; i++) {

        ringDashes.push([
            [//OFF (0)
                0, ringRadiiHalf[i] + ((TWO_PI * ringRadii[i]) / numSteps) - ringRadiiHalf[i]
            ], [//ON (1)
                ringRadiiHalf[i], ((TWO_PI * ringRadii[i]) / numSteps) - ringRadiiHalf[i]
            ]
        ]);
        for (var j = 0; j < numSteps; j++) {//for each octave
            //rings.push(new WNote( two.makeCircle(0,0,ringRadii[i])) );
            if(rings.length < 9 * numSteps){
                rings.push(new WNote( two.makeCircle(0,0,ringRadii[i])) );
            }else{
                rings[i * numSteps + j].radius = ringRadii[i];
            }

            for (var k = 0; k < numSteps; k++) {//for each note within the octave
                if (j != k) {
                    ringDashArrays.push(ringDashes[i][OFF][0]);
                    ringDashArrays.push(ringDashes[i][OFF][1]);
                } else {
                    ringDashArrays.push(ringDashes[i][ON][0]);
                    ringDashArrays.push(ringDashes[i][ON][1]);
                }

            }
            rings[i * numSteps + j].noFill();
            rings[i * numSteps + j].visible = false;
            rings[i * numSteps + j].dash = ringDashArrays;
            ringDashArrays = [];
            rings[i * numSteps + j].dashOffset = ringRadiiHalf[i] * 0.5;
            rings[i * numSteps + j].linewidth = (mainNoteArea.linewidth / numOcts) * 0.75;

            if (i < 2) {//Make inner rings white
                rings[i * numSteps + j].stroke = 'rgba(150 100% 0.4';//'rgba(255,255,255,1)';
            } else {
                rings[i * numSteps + j].stroke = 'hsl(0 100% ' + (i * 10 + 20) + '% / 1)';//'rgba(' + (i * 25) + ', 255,' + (i * 25) + ',1)';
            }
        }
    }
    /*for(var i = 0; i < 9; i++){
        for(var j = 0; j < numSteps; j++){
            rings[i * numSteps + j].linewidth  = 1;
            rings[i * numSteps + j].radius = (i * 50) + 200;
        }

    }*/

}

function setWheelOctave(distance){
    let wheelOctaveCheck = 0;
    switch (true) {
        case distance > insideRadius && distance <= insideRadius + ringFit:
            wheelOctaveCheck = 0;
            break;
        case distance > insideRadius + ringFit && distance <= insideRadius + ringFit * 2:
            wheelOctaveCheck = 0;
            break;
        case distance > insideRadius + ringFit * 2 && distance <= insideRadius + ringFit * 3:
            wheelOctaveCheck = 1;
            break;
        case distance > insideRadius + ringFit * 3 && distance <= insideRadius + ringFit * 4:
            wheelOctaveCheck = 2;
            break;
        case distance > insideRadius + ringFit * 4 && distance <= insideRadius + ringFit * 5:
            wheelOctaveCheck = 3;
            break;
        case distance > insideRadius + ringFit * 5 && distance <= insideRadius + ringFit * 6:
            wheelOctaveCheck = 4;
            break;
        case distance > insideRadius + ringFit * 6 && distance <= insideRadius + ringFit * 7:
            wheelOctaveCheck = 5;
            break;
        case distance > insideRadius + ringFit * 7 && distance <= insideRadius + ringFit * 8:
            wheelOctaveCheck = 6;
            break;
        case distance > insideRadius + ringFit * 8 && distance <= insideRadius + ringFit * 9:
            wheelOctaveCheck = 7;
            break;  
        case distance > insideRadius + ringFit * 9:
            wheelOctaveCheck = 8;
            break;
        
    }
    //console.log("wheeloctavecheck: " + wheelOctaveCheck);
    if(wheelOctaveCheck < numOctaves){
        wheelOctave = wheelOctaveCheck;


    }
    //console.log("w OCT: " + wheelOctave);
}

function checkWheelNote(currClickAngle): number{

    let piDiv12 = Math.PI/numSteps;
    let labelPosDifference: number = 0;
    let temp:number = 0;
    if(currLabelPosition >= 3){
        temp = currLabelPosition - 3;
        temp %= numSteps; 
    }else if(currLabelPosition < 3 && currLabelPosition >= 0){
        temp = numSteps - (3 - currLabelPosition);
    }else{
        temp = (numSteps + (currLabelPosition % numSteps - 3)) % numSteps;
        if(temp < 0 ){
            temp = numSteps + temp;
        }
    }
    temp = numSteps - temp;
    temp %= numSteps;
    let a: number = temp;// a represents number of areas that need to be an octave down
    console.log("A: " +  a);
    //console.log(temp);
    temp += numSteps * (wheelOctave+1);

    let wArea = 0;
    switch (true) {
        case currClickAngle > piDiv12 * -11 && currClickAngle <= piDiv12 * -9:
            wArea = temp + 4;
            if(a >= 8) wArea -= 12;
            break;
        case currClickAngle > piDiv12 * -9 && currClickAngle <= piDiv12 * -7:
            wArea = temp + 5;
            if(a >= 7) wArea -= 12;
            break;
        case currClickAngle > piDiv12 * -7 && currClickAngle <= piDiv12 * -5://12 oclock
            wArea = temp + 6;
            if(a >= 6) wArea -= 12;
            break;
        case currClickAngle > piDiv12 * -5 && currClickAngle <= piDiv12 * -3:
            wArea = temp + 7;
            if(a >= 5) wArea -= 12;
            break;
        case currClickAngle > piDiv12 * -3 && currClickAngle <= piDiv12 * -1:
            wArea = temp + 8;
            if(a >= 4) wArea -= 12;
            break;
        case currClickAngle > piDiv12 * -1 && currClickAngle <= piDiv12 * 1://3 oclock
            wArea = temp + 9;
            if(a >= 3) wArea -= 12;
            break;
        case currClickAngle > piDiv12 * 1 && currClickAngle <= piDiv12 * 3://4
            wArea = temp + 10;
            if(a >= 2) wArea -= 12;
            break;
        case currClickAngle > piDiv12 * 3 && currClickAngle <= piDiv12 * 5://5*when a==1 only this should be an octave lower
            wArea = temp + 11;
            if(a >= 1) wArea -= 12;
            break;
        case currClickAngle > piDiv12 * 5 && currClickAngle <= piDiv12 * 7://6 oclock
            wArea = temp;
            break;
        case currClickAngle > piDiv12 * 7 && currClickAngle <= piDiv12 * 9:
            wArea = temp + 1;
            if(a >= 11) wArea -= 12;
            break;
        case currClickAngle > piDiv12 * 9 && currClickAngle <= piDiv12 * 11:
            wArea = temp + 2;
            if(a >= 10) wArea -= 12;
            break;
        default:
            wArea = temp + 3;//9 oclock
            if(a >= 9) wArea -= 12;
            break;
    }
    //prevWheelNote = wArea;
    //if(onClick == true){
    //playNote(wArea, keyboardVelocity, 1);
        
    //}
    return wArea;

    
    //add currLabelPosition to note parameter
    //currLabelPosition
}


function setPianoZoom(numOcts: number, oct: number, numSteps: number){
    pianoZoomOctaves = numOcts;
    firstBlackKeyValZoom = pianoZoomOctaves * 7;
    let pianoKeyWidth = two.width/(firstBlackKeyValZoom + 2);
    
    //White keys
    for(let i = 0; i < firstBlackKeyIndex; i++){
        pianoKeys[i].position = 
            [(-two.width/2) + (i *  (two.width/firstBlackKeyValZoom) + (two.width/((firstBlackKeyValZoom-1) *2) )), whiteKeysY];
        pianoKeys[i].width = pianoKeyWidth;
        pianoKeys[i].height = pianoHeight;
    }
    //Black Keys
    let newBlackKeyWidth = pianoKeyWidth * 0.8;
    let iBlackKeySpacer = 0;
    let onlyBlackKeys = pianoKeys.length - firstBlackKeyIndex;
    for(let i = 0; i < onlyBlackKeys; i++){
        let j = i % 5;
        if(j == 2 || j == 0){
            if(i != 0){
                iBlackKeySpacer++;
            }
        }
        pianoKeys[i + firstBlackKeyIndex].position = 
            [(-two.width/2) + ((i + iBlackKeySpacer) *  (two.width/firstBlackKeyValZoom) + (two.width/(firstBlackKeyValZoom-1))), 
                whiteKeysY - ((pianoHeight * (1 - blackKeyHeightProportion))/2)];
        pianoKeys[i + firstBlackKeyIndex].width = newBlackKeyWidth;
        pianoKeys[i + firstBlackKeyIndex].height = blackKeyHeight;
    } 

}
//pushes rectangles into array in specific order so that when black 
//keys are clicked other white key notes are not triggered as well
function checkPianoNote(x: number, y:number) : number{
        let keysDOMRects: DOMRect[] = [];
        //push black piano key rects into 
        for(let i = firstBlackKeyIndex; i < (pianoKeys.length); i++){
            //console.log("black: " + i);
            keysDOMRects.push(pianoKeys[i].shape.getBoundingClientRect());
        }
        //check for black keys pressed
        let blackKeyPressed: boolean = false;
        for(let i = 0; i < keysDOMRects.length; i++){
            if (x >= keysDOMRects[i].left && x <= keysDOMRects[i].right && y >= keysDOMRects[i].top && y <= keysDOMRects[i].bottom) {
                blackKeyPressed = true;
                return pianoMap2[i];
                
            }
        }

        //if no black keys pressed, push white keys in 2nd
        if(blackKeyPressed == false){
            for(let i = 0; i < firstBlackKeyIndex; i++){
                //console.log("TEST");
                keysDOMRects.push(pianoKeys[i].shape.getBoundingClientRect());
            }
            //check for white keys pressed
            let startVal = keysDOMRects.length - firstBlackKeyIndex;
            for(let i = startVal; i < keysDOMRects.length; i++){
                //console.log("white playSound: " + (i));
                if (x >= keysDOMRects[i].left && x <= keysDOMRects[i].right && y >= keysDOMRects[i].top && y <= keysDOMRects[i].bottom) {
                    return pianoMap2[i];
                }
            }
        }
        //console.log("LENGTH: " + keysDOMRects.length);
        return -1;//return negative one for NOTHING
    
}

function playNote(noteNum: number, velocity: number, index: number, muted: boolean, isStatic: boolean){
    if(noteNum < 12){return;} 
    let note = 0;//for drawing notes onto ui
    let notePitch = 0;
    if(isStatic){ 
        note = noteNum - 12 - (octave * 12);//for drawing
        notePitch = noteNum;
    }else{
        note = noteNum - 12;
        notePitch = noteNum + (octave * 12);
    }
    if(!muted){
        sampler1.triggerAttack(Tone.Frequency(notePitch, "midi").toNote(), undefined, velocity/200);
    }
    
    

    //checkIsChordTone(noteNum, Tone.Transport.ticks);         
    
    // Define Ring Color:               each channel x 70,  100% Sat, Lightness by octave  
    if(note >= 0 && note < numOctaves * 12){
        
        rings[note].stroke = 'hsl(' + (index * 70) + ' 100% ' + (noteNum * 0.8 + 10) + '% / ' + (velocity) + ')';
        rings[note].visible = true;
        
        pianoKeys[pianoMap[note]].fill = 'hsl(' + (index * 70) + ' 100% ' + (notePitch * 0.8 + 10) + '% / ' + (velocity) + ')';
        
        let hue: number = index * 70;
        let lightness :number = notePitch * 0.8 + 10;
        let flashColor: number = lightness + velocity * 0.6;
        if (flashColor >= 100) flashColor = 100;
        rings[note].startFlashing([hue, 100, lightness, 100], [0,0, flashColor,100],velocity * 1.5 );
        rings[note].play([hue, 100, lightness, 100]);
        rings[note].shake(velocity * 0.06,velocity  * 17);
        if(currentNotesNum.length > 1){
            currentNotesNum.forEach(j => {
                
                if(noteNum % numSteps == j % 12){
                    rings[note].startFlashing([hue, 100, (lightness), 1], [0,0,100,100],150);
                    rings[note].shake(4,1500);
                }else{
                    
                }
            });
        }

    }
    
}

function stopNote(noteNum: number, index: number, muted: boolean, isStatic: boolean){
    if(noteNum < 12){return;} 
    let note = 0;
    let notePitch = 0;
    if(isStatic){ 
        note = noteNum - 12 - (octave * 12);//for drawing
        notePitch = noteNum;
    }else{
        note = noteNum - 12;
        notePitch = noteNum + (octave * 12);
    }
    currentlyPressedNotes.delete(noteNum);
    //console.log(noteNum  + " STOPPED");
    //console.log("currentlyPressed: " + currentlyPressedNotes.values());

   // if (!isSustainPedalDown || !sustainedNotes.has(noteNum)) {
        //console.log("RELEASED");
        if(!muted){
            sampler1.triggerRelease(Tone.Frequency(notePitch, "midi").toNote());
        }
        
    //}
    sustainedNotes.delete(noteNum);

    if(sustain[index-1] == true){
        //console.log("NoteOff: " + e.note.number);
        sustainingNotes[index-1].push(noteNum);
        //console.log("SUSTAINING: " + sustainingNotes[index-1]);

    }else{
        if(note >= 0 && note < numOctaves * 12){
            rings[note].stop(index, notePitch);

            if(pianoMap[note]> firstBlackKeyIndex - 1){
                pianoKeys[pianoMap[note]].fill ='rgba(2,2,2,1)';//reset black key color
            }else{
                pianoKeys[pianoMap[note]].fill = 'rgba(255,255,255,.17)' ;
            }
        }
    }
}

//check if isPlaying, check if note is a chordTone, and is within strong beat tick value
function checkIsChordTone(noteNum: number, tick: number){
    if(isPlaying){
        //console.log("WOOO " + noteNum);
        if(chordName != ""){
            for(let i = 0; i < currentNotesNum.length; i++){
                if(noteNum % 12 == currentNotesNum[i] % 12){
                    //detChRootCircle.startFlashing([100, 100, 100, 0.5], [0,0,100,100], 450);
                    detChCircle.startFlashing([184, 100, 70, 0.1], [0,0,200,100], 350);
                }
            }
        }
    }
}

function setKey(keyNum: number){
    console.log("currKeyPos: " + currKeyPosition);
    if(currKey < keyNum){
        keyRot += -TWO_PI * ((currKey - keyNum)/12);
    }else if(currKey > keyNum){
        keyRot += -TWO_PI * ((currKey - keyNum)/12);
    }
    currKeyPosition = calculatePosition(keyRot);
    currKey = keyNum;
    setKeyText();
}

function chordDetectON(noteNum: number ){

    //CODE FOR CHORD DETECTION
    //sampler.triggerAttack(Tone.Frequency(noteNum, "midi").toNote(), undefined, e.note.rawAttack/127);
    currentNotesNum.push(noteNum);
    if(currentNotesNum.length > 1){
        currentNotesNum.sort();
        for(var i = 0; i < currentNotesNum.length; i++){
            currentNotes[i] = (TonalMidi.midiToNoteName(currentNotesNum[i], { sharps: sharpNotes }));
        }
        detectedChord = Chord.detect(currentNotes);

        if(detectedChord.length <= 1){
            chordName = detectedChord[0];

            
        }else{
            if(detectedChord[0].includes("m#5")){
                chordName = detectedChord[1];
            }else{
                chordName = detectedChord[0];
            }
            
        }
        

        //Push chord name to recorded chord progression array
        if(isPlaying && chordName != undefined){
            console.log("PUSHED" + chordName + ", " + Tone.Transport.position);
            recordedChordProgression.push([chordName,"" + Tone.Transport.position ]);
        }
        
        //set chordRoot and innerLabels
        if(detectedChord.length > 0){
            let tempChar: string = chordName.charAt(0);
            var tempOffset: number = 0;
            switch (tempChar){
                case 'C': tempOffset = 0;
                    break;
                case 'D': tempOffset = 2;
                    break;
                case 'E': tempOffset = 4;
                    break;
                case 'F': tempOffset = 5;
                    break;
                case 'G': tempOffset = 7;
                    break;
                case 'A': tempOffset = 9;
                    break;
                case 'B': tempOffset = 11;
                    break;
            }
            if(sharpNotes == true){
                if(chordName.charAt(1) == '#'){
                    tempOffset++;
                    //setChordManually(chordName.charAt), currentNotesNum);
                }
            }else{
                if(chordName.charAt(1) == 'b'){
                    tempOffset--;
                    if(tempOffset < 0){
                        tempOffset = 12 + tempOffset;
                    }
                }
            }
            console.log("tempOffset " + tempOffset + " currKey: " + currKey);

            //Center text roman numeral code!
            let romNum =  tempOffset - currKey < 0 ? tempOffset - currKey + numSteps: tempOffset-currKey;
            //if something
            //chordName = romanNumerals.processChord(chordName, tempOffset - currKey < 0 ? tempOffset - currKey + numSteps: tempOffset-currKey  );

            setChordManually(0, tempOffset, currentNotesNum);
            setInnerLabels(tempOffset, [1,0,0,0,0,0,0,0,0,0,0,0]);
            
            if(autoRotateSetting == 2){//if autorotate set to CHRODS
                
            }
        }
    }
    
    //console.log(currentNotes);
    //console.log(currentNotesNum);
    //END CHORD DETECTION CODE
    
}

function chordDetectOff(noteNum: number){
    //detectionChannel 16
    //CODE FOR CHORD DETECTION 
    //sampler.triggerRelease(Tone.Frequency(e.note.number, "midi").toNote());
    let removedNoteIndex: number = currentNotesNum.indexOf(noteNum);
    currentNotesNum.splice(removedNoteIndex,removedNoteIndex + 1);
    currentNotes.splice(removedNoteIndex,removedNoteIndex + 1);
    detectedChord = Chord.detect(currentNotes);
    if(detectedChord.length <= 2){
        chordName = detectedChord[0];
    }else{
        chordName = detectedChord[1];
    }
    if(currentNotesNum.length < 3){
        setChordManually(0, -1, []);
        setInnerLabels(0, [0,0,0,0,0,0,0,0,0,0,0,0]);
    }else{
        setChordManually(0, currentNotesNum[0] % numSteps, currentNotesNum);
    }
    //console.log(detectedChord);
    //END CHORD DETECTION CODE
}


function resetChordDetect(){
    setChordManually(0, -1, []);
    setInnerLabels(0, [0,0,0,0,0,0,0,0,0,0,0,0]);
    currentNotesNum = [];
    chordName = "";
}

function panic(){
    sampler1.releaseAll();
    rings.forEach(element => {
        element.visible = false;
        element.clearCurrentNotesColors();
        
    });
    for(let i = 0; i < pianoKeys.length; i++){
        if(pianoMap[i]> firstBlackKeyIndex - 1){
            pianoKeys[pianoMap[i]].fill ='rgba(2,2,2,1)';
        }else{
            pianoKeys[pianoMap[i]].fill = 'rgba(255,255,255,.17)' ;
        }
    };
}

// Function to calculate distance from the center of the circle
function calculateDistanceFromCenter(centerX: number, centerY: number, mouseX: number, mouseY: number): number {
    const dx = mouseX - centerX;
    const dy = mouseY - centerY;
    return Math.sqrt(dx * dx + dy * dy);
}


// Helper function to calculate angle
function calculateAngle(mx, my, spx, spy) {
    return Math.atan2(my - spy, mx - spx);
}

function calculateNearestMultiple(p){
    return Math.round(p / (Math.PI / 6)) * (Math.PI / 6);
}

// Function to update total rotation
function updateTotalRotation(newAngle, currentAngle) {
    let delta = newAngle - currentAngle;
    if (delta > Math.PI) {
        delta -= 2 * Math.PI; // Crossing from -π to π
    } else if (delta < -Math.PI) {
        delta += 2 * Math.PI; // Crossing from π to -π
    }
    //totalRotation += delta;
    return delta;
}

function shakeWheel(amount: number, time: number){
    mainNoteArea.shake(amount, time);
    detChRootCircle.shake(amount,time);
    chordRootCircle.shake(amount,time);
    chordCircle.shake(amount,time);
    detChCircle.shake(amount,time);
}

//PERFORMANCE MODE FUNCTIONS

function addNoteToPerformance(tick: number, on: number, noteNum: number, velocity: number, instNum: number){
    
    performance.push([tick, on, noteNum, velocity, instNum]);
}

function printPerformance(){
    for(let i = 0; i < performance.length; i++){
        console.log("T: " + performance[i][0] +", " + performance[i][1] +  ", N: " + performance[i][2] );
    }
    
}

function printLastPerformance(){
    for(let i = 0; i < lastPerformance.length; i++){
        console.log("T: " + lastPerformance[i][0] +", " + lastPerformance[i][1] +  ", N: " + lastPerformance[i][2] );
    }
    
}

function printLastRecordedChordProgression()
{
    
    console.log("ChProg: " + recordedChordProgression);
    
}
function uiStart(e: any){
    let rect: DOMRect = keySprite.getBoundingClientRect();
    let playBtn: DOMRect = playButton.getBoundingClientRect();
    //let oneKey: DOMRect = pianoKeys[50].shape.getBoundingClientRect();
    //let keysDOMRects: DOMRect[] = [];

    var center = {//REPEAT CODE
        x: two.width /2,
        y: two.height/2
    }

    let x = e.clientX;
    let y = e.clientY;
    var sp = wheelStartAngle;
    let distance = calculateDistanceFromCenter(center.x, center.y, x, y);
    //key sprite clicked
    if (x >= rect.left && x <= rect.right+10 && y >= rect.top && y <= rect.bottom) {//mousedown on KEY SPRITE
        keyFlag = true;
        currKeyAngle = calculateAngle(x,y,sp.x,sp.y);
        keyCircle.visible = true;
        //mainNoteArea.rotation += ((-TWO_PI * x) - mainNoteArea.rotation) * drag;
    //Play Button Clicked    
    }else if(x >= playBtn.left && x <= playBtn.right+10 && y >= playBtn.top && y <= playBtn.bottom){
        
        if(isPlaying){//stop
            isPlaying = false;
            Tone.Transport.stop();
            if(performance.length > 0){//save last performance
                lastPerformance = performance;
            }
            loadLastPerformance();
            console.log("STOP");
            playButton.fill = styles.fill;
            playButton.opacity = styles.opacity;
            panic();
            resetChordDetect();
            printLastPerformance();
            printLastRecordedChordProgression();
            stopVideo();
            
            if(recordedChordProgression.length > 0){
                prevRecordedChordProgression = recordedChordProgression;
                recordedChordProgression = [];
            }
            //clear tempoMapMs array
            if(tempoMapMs.length > 0){
                prevTempoMapMs = tempoMapMs;
                currSong.tempoMap = tempoMapMs;
                console.log(prevTempoMapMs);
                tempoMapMs = [];
                //setTempoMap();
            }
            

            

        }else{//play
            startTime = Date.now();
            performance = [];
            playButton.fill = "rgb(0,255,0)";
            isPlaying = true;
            Tone.Transport.bpm.value = currSong.bpm;
            Tone.Transport.position = "0:0:0";
            Tone.Transport.start();
            console.log("PLAY");
            currentPlace = 0;

            playVideo();
            pauseVideo();
            shakeWheel(75,160);//beat one shake!
            
        }
        
    }else{
        //LabelCircle Clicked
        if(distance > outsideRadius - (labelCircle.linewidth/2) && distance < outsideRadius + (labelCircle.linewidth/2) ){
            if(keyFlag == false){
                labelFlag = true;
                prevKeyRot = keyRot;
                currLabelAngle = calculateAngle(x,y,sp.x,sp.y);
                currKeyAngle = calculateAngle(x,y,sp.x,sp.y);
                //console.log(keyRot + "," + prevKeyRot);
                //selectedLabelNum = i;
                labelCircle.visible = true;
            }

        //Wheel Note Area Clicked
        }else if(distance < outsideRadius + (labelCircle.linewidth/2) && distance > insideRadius ){
            //currClickAngle = calculateAngle(x,y, sp.x, sp.y);
            wheelNoteClicked = true;
            setWheelOctave(distance);
            
                currWheelNote = checkWheelNote(calculateAngle(x,y, sp.x, sp.y));
                playNote(currWheelNote, keyboardVelocity, 1,false,false);
            
            
        }
    }
    //Piano Area Clicked
    if(mouseIsOverPiano){//PIANO KEYS MOUSE DOWN
        currPianoNote = checkPianoNote(x, y);
        playNote(currPianoNote + 12 /*+ (octave * 12)*/, keyboardVelocity, 1,false,false);
        pianoKeyPressed = true;
    }
    prevPianoNote = currPianoNote;
    prevWheelNote = currWheelNote;
}

function uiMove(e: any){
    let playBtn: DOMRect = playButton.getBoundingClientRect();
    var center = {
        x: two.width /2,
        y: two.height/2
    }
    var mouse = {
        x: e.clientX,
        y: e.clientY
    }
    

    if (keyFlag){
        //let newKey;
        newKeyAngle = calculateAngle(mouse.x, mouse.y, wheelStartAngle.x, wheelStartAngle.y);
        keyRot += updateTotalRotation(newKeyAngle, currKeyAngle);

        //New Key TESTING
        newKey = calculatePosition(keyRot);
        console.log("THIS NEW KEY: " + newKey);
        
        currKeyAngle = newKeyAngle;
    }
    if(isPlaying){
        if(mouse.x >= playBtn.left && mouse.x <= playBtn.right+10 && mouse.y >= playBtn.top && mouse.y <= playBtn.bottom){
            
            playButton.opacity = styles.opacity;
        }else{
            playButton.opacity = 0.1;
        }
    }else{
        playButton.opacity = styles.opacity;

    }
    

    if(labelFlag){
        var newLabelAngle = calculateAngle(mouse.x, mouse.y, wheelStartAngle.x, wheelStartAngle.y);     
        newKeyAngle = newLabelAngle;
        let totalRot = updateTotalRotation(newLabelAngle, currLabelAngle);
        labelRot += totalRot;
        keyRot += totalRot;
        currLabelAngle = newLabelAngle;
        currKeyAngle = newKeyAngle;
    }
    let distance = calculateDistanceFromCenter(center.x, center.y, mouse.x, mouse.y)
    //console.log("DISTANCE: " + distance);
    if(distance > outsideRadius - (labelCircle.linewidth/2) && distance < outsideRadius + (labelCircle.linewidth/2) ){
        if(keyFlag == false){
            labelCircle.visible = true;
        }else{
            labelCircle.visible = false;
        }
    }else{//outside of 
        if(labelFlag == false){
            labelCircle.visible = false;
        }
        
    }

    //MOUSE MOVES OVER KEYS
    if(mouse.y > (two.height* 0.88)){
        mouseIsOverPiano = true;
    }else{
        mouseIsOverPiano = false;
        
    }
    if(mouseIsOverPiano != prevMouseIsOverPiano){//checks if mouse leaves piano area 
        if(currPianoNote >= 0)
            stopNote(currPianoNote + 12, 1, false, false);
    }
    if(mouseIsOverPiano == true){ 
        if(pianoKeyPressed == true){
            currPianoNote = checkPianoNote(mouse.x, mouse.y);
            //console.log("CURR: " + currPianoNote + ", PREV: " + prevPianoNote);
            if(currPianoNote != prevPianoNote){
                stopNote(prevPianoNote +12, 1, false, false);
                playNote(currPianoNote + 12, keyboardVelocity, 1, false, false);  
            }
        }
        //console.log("Curr: " + currPianoNote + ", Prev: " + prevPianoNote);
    }

    if(wheelNoteClicked == true){
        setWheelOctave(distance);
        currWheelNote = checkWheelNote(calculateAngle(mouse.x, mouse.y, wheelStartAngle.x, wheelStartAngle.y));
        if(currWheelNote != prevWheelNote){
            stopNote(prevWheelNote, 1, false, false);
            
            playNote(currWheelNote, keyboardVelocity, 1, false, false);

        }
    }
    
    prevMouseIsOverPiano = mouseIsOverPiano;
    prevPianoNote = currPianoNote;
    prevWheelNote = currWheelNote;
    //console.log("L: " + labelRot + ", K: " + keyRot + ", currK: " + currKeyAngle + ", currL: " + currLabelAngle );
}

function uiEnd(e: any){
    //let x = e.clientX;
    //let y = e.clientY;
    //let keysDOMRects: DOMRect[] = [];
    if(keyFlag == true) {
        
        console.log("key RELEASED");
        let nearestMultipleKey = calculateNearestMultiple(keyRot);
        //need to ensure key matches keyRot
        keyRot = nearestMultipleKey;

        //set currKey here!
        
        //console.log("currKeyPosition: " + currKeyPosition);
        //setCurrKey function!!
        //setKey();
        currKeyPosition = calculatePosition(keyRot);
        currKey = setCurrKey(currLabelPosition, currKeyPosition);
        setKeyText();
        //keyText.value = "KEY: " + noteLetters[noteLetterType][(currKey + 3) % numSteps] + " " + scaleSelector.options[currScale].text;
        
        //reset keyFlag to false
        keyFlag = false;
        keyCircle.visible = false;
    }
    if(labelFlag == true){
        //console.log("label Released");
        
        labelReleased();
        console.log("currLabelPosition: " + currLabelPosition);
        console.log("currKeyPosition " + currKeyPosition);
        //PROBLEM
        //keyRot = labelRot;
        labelFlag = false;
        labelCircle.visible = false;
    }
    if(mouseIsOverPiano){
        //panic();
    }
    if(wheelNoteClicked){
        //console.log("PREV WHEEL NOTE: " + prevWheelNote);
        stopNote(currWheelNote, 1, false, false);
        //panic();
        wheelNoteClicked = false;
        
    }
    if(pianoKeyPressed){
        stopNote(currPianoNote + 12 /*+ (octave * 12)*/, 1, false, false);
        pianoKeyPressed = false;

    }
    
    
    //console.log("newKey" + (newKey) + ", mainNoteArea: " + mainNoteArea.rotation);
    //console.log("labelRot: " + labelRot + ", keyRot: " + keyRot)
}

function uiCancel(){

}

function touch2Mouse(e: any)
{
  var theTouch = e.changedTouches[0];
  var mouseEv;

  switch(e.type)
  {
    case "touchstart": mouseEv="mousedown"; break;  
    case "touchend":   mouseEv="mouseup"; break;
    case "touchmove":  mouseEv="mousemove"; break;
    default: return;
  }

  var mouseEvent = document.createEvent("MouseEvent");
  mouseEvent.initMouseEvent(mouseEv, true, true, window, 1, theTouch.screenX, theTouch.screenY, theTouch.clientX, theTouch.clientY, false, false, false, false, 0, null);
  theTouch.target.dispatchEvent(mouseEvent);

  e.preventDefault();
}


//Basic Pitch Stuff
const mySuccessCallback: onSuccessCallback = (notes: NoteEvent[]) => {
    
    console.log("All the notes: ");

    for(let i = 0; i < notes.length; i++){
        console.log(notes[i].pitch + ", " + notes[i].onset);
    }
}
const myFailureCallback: onErrorCallback = (eh: any) => {
    console.log("It Failed man...");
}
const myProgressCallback: onProgressCallback = (percentage: number) => {
    //muteBTN.textContent = percentage + "";
    percentage *= 100;
    //analyzeBTN.textContent = percentage + "%";
    console.log("Processing Percentage: " + percentage);
}

function getBar(timeString: any){
    let parts = timeString.split(':');
    return parts;

}


function backOneBar(){
    let currentBar = getBar(Tone.Transport.position);
    let currentMs = Date.now() - barMs;
    let currentPlayerTime = player.getCurrentTime();

    if(currentBar[1] > 0){//if on second beat or later > go back to beginning of this bar
        console.log("currentMs: " + currentMs);
        panic();
        player.seekTo( currentPlayerTime - (currentMs * 0.001), true );
        Tone.Transport.position = currentBar[0] + "0:0";

    }else{//if still on beat 1, go to previous bar
        if(currentBar[0] > 0){
        }
    }
    
    

    

}

function forwardOneBar(){


}

// YouTube
// Global YT namespace declaration to make TypeScript aware of the YouTube IFrame API
declare namespace YT {
    class Player {
        constructor(elementId: string, options: PlayerOptions);
        playVideo(): void;
        stopVideo(): void;
        pauseVideo(): void;
        getCurrentTime(): number;
        seekTo(): void;
        seekTo(seconds: number, allowSeekAhead: boolean): void; // Add this line
        setPlaybackRate(suggestedRate: number): void; // Add this line
        getAvailablePlaybackRates(): number[]; // Add this line
        getPlaybackRate():Number;
        
        // Add other methods as needed
    }

    interface PlayerOptions {
        height?: string;
        width?: string;
        videoId: string;
        playerVars?: PlayerVars;
        events?: PlayerEvents;
    }

    interface PlayerEvents {
        onReady?: (event: OnReadyEvent) => void;
        onStateChange?: (event: OnStateChangeEvent) => void;
        // Add other event handlers as needed
    }

    interface PlayerVars {
        autoplay?: number;
        controls?: number;
        start?: number;
        end?: number;
        loop?: number;
        playlist?: string;
        rel?: number;
        showinfo?: number;
        modestbranding?: number;
        // Add other player variables as needed
    }

    enum PlayerState {
        UNSTARTED = -1,
        ENDED = 0,
        PLAYING = 1,
        PAUSED = 2,
        BUFFERING = 3,
        CUED = 5
    }

    interface OnReadyEvent {
        target: Player;
    }

    interface OnStateChangeEvent {
        data: PlayerState;
    }
}

// Your TypeScript code that uses the YT namespace...

let isVideoPlaying = false;
let player: YT.Player;


const apiKey = 'AIzaSyAeDqTIhCu6nexdlRSV0-z8xVLlP2Uk3m8';
//const videoId = 'tg00YEETFzg';//'CpB7-8SGlJ0';

//const url = `https://www.googleapis.com/youtube/v3/videos?part=snippet,contentDetails,statistics&id=${videoId}&key=${apiKey}`;

/*async function fetchVideoDetails() {
    try {
        const response = await fetch(url);
        const data = await response.json();
        displayVideoDetails(data.items[0]);
    } catch (error) {
        console.error('Fetching video details failed', error);
    }
}

function displayVideoDetails(video: any) {
    const container = document.getElementById('videoContainer');
    const title = video.snippet.title;
    const description = video.snippet.description;
    // Embed video using iframe
    const videoEmbed = `<iframe width="560" height="315" src="https://www.youtube.com/embed/${video.id}" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;

    if (container) {
        container.innerHTML = 
            //<h1>${title}</h1>
            //<p>${description}</p>
            `
            '${videoEmbed}
            <!-- Display more details as needed -->
        `;
    }
}
*/

window.onYouTubeIframeAPIReady = function() {
    console.log("YouTube IFrame API is ready and player is created.");
    //createYouTubePlayer('CpB7-8SGlJ0', 200); // Directly create the player here
};

function createYouTubePlayer(videoId: string, startSeconds: number) {
    player = new YT.Player('videoContainer', {
        height: '320',
        width: '480',
        videoId: videoId,
        playerVars:{
            'autoplay': 0,//Auto-play the video on load
            'start': startSeconds, // Start playback at startSeconds

        },
        events: {
            'onReady': onPlayerReady,
            'onStateChange': onPlayerStateChange
        }
    });
}

function onPlayerReady(event) {
    // Set the initial volume level (0-100)
    event.target.setVolume(100); // Sets volume to 50%
}

// Define the event listener function
function onPlayerStateChange(event: YT.OnStateChangeEvent) {
    if (event.data == YT.PlayerState.PLAYING) {
        isVideoPlaying = true;
        console.log('Video is now playing:', isVideoPlaying);
    } else {
        // Optionally, set isVideoPlaying to false if the video is paused or stopped
        isVideoPlaying = false;
        console.log('Video Stopped');
    }
}

function changePlaybackSpeed(rate: number) {
    if (player && player.setPlaybackRate) {
        player.setPlaybackRate(rate);
        Tone.Transport.bpm.value = globalBPM * rate;
    }
}

function playVideo() {
    if (player && player.playVideo) {
        player.playVideo();
        //player.seekTo(startTime, true);
    }
}

function stopVideo() {
    if (player && player.stopVideo) {
        player.stopVideo();
    }
}

function pauseVideo(){
    if(player && player.pauseVideo){
        player.pauseVideo();
    }
}

function getCurrentTime() {
    return this.player.getCurrentTime();
}

function adjustPlayerSize() {
    const aspectRatio = 16 / 9; // Change to 9 / 16 if the video is portrait
    const container = document.getElementById('videoContainer');
    if (!container) return;

    if (window.innerWidth / window.innerHeight > aspectRatio) {
        container.classList.add('landscape');
        container.classList.remove('portrait');
    } else {
        container.classList.add('portrait');
        container.classList.remove('landscape');
    }
}

window.addEventListener('resize', adjustPlayerSize);
window.addEventListener('orientationchange', adjustPlayerSize);

//fetchVideoDetails();
// Call the function with the video ID
createYouTubePlayer(currSong.id, 0);
adjustPlayerSize();
//updatePlaybackRateButtons();









function updatePlaybackRateButtons() {
    console.log("HHAHAHAH");
    console.log(player);
    console.log(player.getAvailablePlaybackRates());
    if (player && player.getAvailablePlaybackRates) {
        const availableRates = player.getAvailablePlaybackRates();
        console.log('Available playback rates:', availableRates);
        // Here you could enable/disable buttons based on availableRates
    }
}